import { ButtonProps } from "@mui/material";
import { useState } from "react";

// Button Intended for use as quadrenary
const LineButton = (props: ButtonProps) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  return (
    <button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        backgroundColor: isHover ? "#A69B8D" : "#1A1A1A",
        color: isHover ? "#FFFFFF" : "#A69B8D",
      }}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default LineButton;
