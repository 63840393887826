import { useState, useEffect, useRef, useReducer } from "react";
import { default as ForceGraph3D } from "3d-force-graph";
import { EveInput } from "@eveworld/ui-components";
import * as THREE from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { TTFLoader } from "three/examples/jsm/loaders/TTFLoader";

import "./canvas.css";

import {
  deriveCameraPosition,
  deriveColorFromConstellationId,
  deriveFocusPosition,
  extractMiddleOfCoords,
  getCoords,
} from "./utils";
import {
  distanceBetweenTwo3DPoints,
  lightYearsBetweenTwo3DPoints,
  useEffectOnce,
} from "@/utils";
import { initialMapReducerState, MapActions, mapReducer } from "./reducer";
import { DYNAMIC_SHADER_MATERIAL, updateNode } from "./threehandlers";
import { COLOR_THEME } from "./constants";
import { is } from "cypress/types/bluebird";

const GalaxyMap = () => {
  const [state, dispatch] = useReducer(mapReducer, initialMapReducerState);

  const graphRef = useRef<null | any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  console.log("rerenders", state, graphRef);

  const [nodeMesh, setNodeMesh] = useState<THREE.InstancedMesh | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [destError, setDestError] = useState<string | null>(null);
  useEffectOnce(() => {
    // Add a label to the focused node
    const loader = new TTFLoader();
    const fontLoader = new FontLoader();
    console.log("Loading font");
    loader.load("./disket_mono_regular.ttf", (fnt) =>
      dispatch({
        type: MapActions.SET_INITIALIZED,
        payload: { font: fontLoader.parse(fnt), graphData: getCoords() },
      })
    );
    const graph = ForceGraph3D()(graphRef.current);

    dispatch({
      type: MapActions.SET_GRAPH,
      payload: graph,
    });
  });

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  const visualizeRay = () => {
    if (!state.graph?.scene()) return;

    const ray = raycaster.ray;

    const rayPathGeometry = new THREE.BufferGeometry().setFromPoints([
      ray.origin,
      ray.origin.clone().add(ray.direction.clone().multiplyScalar(100)), // Extend the ray
    ]);

    const rayPathMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
    const rayPath = new THREE.Line(rayPathGeometry, rayPathMaterial);

    state.graph?.scene().add(rayPath);
  };
  // graphRef.current.onNodeClick((node) => {
  //   console.log("Node clicked:", node);
  // });

  // graphRef.current.onLinkClick((link) => {
  //   console.log("Link clicked:", link);
  // });
  const onMouseMove = (event: MouseEvent) => {
    // Normalize mouse position to range []
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  };

  const onMouseClick = () => {
    // console.log("Clicked on graph", state, nodeMesh);
    // if (!state.graph || !nodeMesh) return;
    // // Intersect with the instances in the InstancedMesh
    // // console.log("Clicked on graph. Intersects with:", nodeMesh);
    // // visualizeRay();
    // console.log("Mouse move", mouse);
    // console.log("Clicked on graph. Intersects with:", nodeMesh);
    // const intersects = raycaster.intersectObject(nodeMesh!);
    // // Iterate over the intersected objects
    // intersects.forEach((intersection) => {
    //   const object = intersection.object;
    //   // Make sure you're calling updateMatrixWorld on the object that needs it
    //   if (object instanceof THREE.Object3D && object.type === "Node") {
    //     object.updateMatrixWorld(true);
    //   }
    // });
    // console.log(
    //   "Clicked on graph end",
    //   intersects,
    //   mouse,
    //   state.graph.camera().position
    // );
    // raycaster.setFromCamera(mouse, state.graph.camera());
    // // console.log("Clicked on graph end", intersects);
    // if (intersects.length > 0) {
    //   for (const intersection of intersects) {
    //     const object = intersection.object;
    //     if (object instanceof THREE.Object3D) {
    //       console.log("CLICKED ON NODE!:", object);
    //       dispatch({
    //         type: MapActions.SET_FOCUSED_NODE,
    //         payload: selectedNode,
    //       });
    //       break;
    //     }
    //   }
    // }
  };
  console.log("rerenders");
  // Resize the graph on window resize
  useEffect(() => {
    console.log("Resizing graph");
    const resizeGraph = () => {
      console.log("Resizing graphin");
      if (state.graph && graphRef.current) {
        const { clientWidth, clientHeight } = graphRef.current;
        state.graph.width(clientWidth);
        state.graph.height(clientHeight);
      }
    };

    // Initial sizing
    resizeGraph();
    // Observe container size changes
    const resizeObserver = new ResizeObserver(() => resizeGraph());
    resizeObserver.observe(containerRef.current!);

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, [state.graph, window.innerWidth, window.innerHeight]);

  useEffect(() => {
    if (!graphRef || !graphRef.current) return;
    graphRef.current.innerHTML = "";
    const graph = ForceGraph3D({ controlType: "orbit" })(graphRef.current);
    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(0.15, 0.15),
      0.15,
      1,
      0
    );
    graph.postProcessingComposer().addPass(bloomPass);
    dispatch({
      type: MapActions.SET_GRAPH,
      payload: graph,
    });
    // Add the raycasting listener
    graphRef.current.addEventListener("mousemove", onMouseMove);
    graphRef.current.addEventListener("click", onMouseClick);

    return () => {
      if (graphRef.current) {
        graphRef.current.removeEventListener("mousemove", onMouseMove);
        graphRef.current.removeEventListener("click", onMouseClick);
      }
    };
  }, []);
  // useEffect(() => {
  //   console.log("nodeMesh updated", nodeMesh);
  // }, [nodeMesh]);

  const validLinks = state.links.filter(
    (link) => state.nodes[link.source] && state.nodes[link.target]
  );
  useEffect(() => {
    if (!state.isDataInitialized) return;
    if (
      !state.graph ||
      state.nodes.length === 0
      // !initialFocusedCoords
    ) {
      return;
    }
    console.log("9");

    const validLinks = state.links.filter(
      (link) => state.nodes[link.source] && state.nodes[link.target]
    );
    const colorMap = Object.keys(state.regionMap).reduce<
      Record<string, THREE.Color>
    >((acc, id) => {
      acc[id] = new THREE.Color(
        deriveColorFromConstellationId(
          parseInt(id),
          `0x${COLOR_THEME.selectedSystemColor.toString(16)}`
        )
      );
      return acc;
    }, {});
    // console.log("initialFocusedCoords", initialFocusedCoords);
    console.time("Graph_Init");
    // const basicMeshMaterial = new THREE.MeshBasicMaterial({
    //   color: COLOR_THEME.baseSystemColor,
    //   opacity: COLOR_THEME.baseSystemOpacity,
    //   transparent: true,
    //   // vertexColors: true,
    // });

    const colors = new Float32Array(state.nodes.length * 3);
    // Assign colors based on regionID
    state.nodes.forEach((node, i) => {
      const color = colorMap[node.regionID];
      colors[i * 3] = color.r;
      colors[i * 3 + 1] = color.g;
      colors[i * 3 + 2] = color.b;
    });

    const material = DYNAMIC_SHADER_MATERIAL;

    const instanceMesh = new THREE.InstancedMesh(
      new THREE.SphereGeometry(0.08, 16, 16), // Shared geometry
      material, // Shared material
      state.nodes.length // Number of instances
    );
    // Add color attribute to the geometry
    instanceMesh.geometry.setAttribute(
      "instanceColor",
      new THREE.InstancedBufferAttribute(colors, 3, false)
    );
    setNodeMesh(instanceMesh);

    const linksInstancedMesh = new THREE.InstancedMesh(
      new THREE.CylinderGeometry(0.05, 0.05, 1, 8).rotateX(Math.PI / 2), // Rotate to align with Z-axis
      new THREE.MeshBasicMaterial({
        color: COLOR_THEME.linkColor,
        opacity: COLOR_THEME.linkOpacity,
        transparent: true,
      }),
      validLinks.length
    );

    state.graph
      .graphData(state as any)
      .cooldownTicks(0)
      .nodeAutoColorBy("relationsLength")
      .nodeRelSize(0)
      .nodeResolution(4)
      .linkResolution(4)
      .nodeLabel("")
      .width(graphRef.current?.clientWidth || window.innerWidth)
      .height(graphRef.current?.clientHeight || window.innerHeight)
      .linkThreeObjectExtend(false)
      .nodeThreeObjectExtend(false)
      .cameraPosition(extractMiddleOfCoords(state.nodes))
      // @ts-ignore - This is a hack to get around the typescript definitions
      .nodeThreeObject(() => null)
      // @ts-ignore - This is a hack to get around the typescript definitions
      .linkThreeObject(() => null) // Disable link rendering
      .linkPositionUpdate(() => null) // Skip link positioning
      .nodeResolution(4)
      .linkWidth(0)
      .linkLabel("name")
      // .onEngineTick(updateLinks)
      .enableNodeDrag(false)
      // .onNodeClick((node) => {
      //   dispatch({ type: MapActions.SET_FOCUSED_NODE, payload: node });
      // })
      .scene().background = new THREE.Color(0x000000);
    // Add tick handler to update positions
    // graph.onEngineTick(() => {
    //   // Update positions if needed
    //   graphData.links.forEach((link: any, index: number) => {
    //     const start = new THREE.Vector3(
    //       link.source.x,
    //       link.source.y,
    //       link.source.z
    //     );
    //     const end = new THREE.Vector3(
    //       link.target.x,
    //       link.target.y,
    //       link.target.z
    //     );

    //     const linkObj = graph.scene().getObjectByName(`link-${index}`);
    //     if (linkObj) {
    //       linkObj.position.copy(start.clone().add(end).multiplyScalar(0.5));

    //       const direction = end.clone().sub(start);
    //       linkObj.quaternion.setFromUnitVectors(
    //         new THREE.Vector3(1, 1, 1),
    //         direction.normalize()
    //       );
    //     }
    //   });
    // });

    state.graph.scene().add(instanceMesh, linksInstancedMesh);
    state.graph.scene().scale.set(1, -1, 1);
    if (linksInstancedMesh.parent) {
      linksInstancedMesh.parent.rotation.set(0, 0, 0); // Try resetting the parent's rotation
      linksInstancedMesh.parent.updateMatrixWorld(true);
    }
    const upVector = new THREE.Vector3(0, 0, 1); // Default up orientation for the cylinder
    validLinks.forEach((link, i) => {
      const sourcePos = new THREE.Vector3(
        state.nodes[link.source].x,
        state.nodes[link.source].y,
        state.nodes[link.source].z
      );
      const targetPos = new THREE.Vector3(
        state.nodes[link.target].x,
        state.nodes[link.target].y,
        state.nodes[link.target].z
      );

      // Calculate center point and length
      // Calculate center point and length
      const center = new THREE.Vector3()
        .addVectors(sourcePos, targetPos)
        .multiplyScalar(0.5);
      const length = sourcePos.distanceTo(targetPos);

      // Create direction vector and quaternion
      const direction = new THREE.Vector3()
        .subVectors(targetPos, sourcePos)
        .normalize();
      const quaternion = new THREE.Quaternion();

      quaternion.setFromUnitVectors(upVector, direction);

      // Create matrix
      const matrix = new THREE.Matrix4();
      matrix.compose(
        center,
        quaternion,
        new THREE.Vector3(1, 1, length) // Scale Z axis for length since we're using Z as base
      );

      linksInstancedMesh.setMatrixAt(i, matrix);
    });

    linksInstancedMesh.instanceMatrix.needsUpdate = true;
    // graph.scene().add(linksInstancedMesh);

    // if (
    //   sourceNode.a_name === "M.699.MNI" &&
    //   targetNode.a_name === "L.KE9.J0I"
    // ) {
    //   console.log(
    //     "Nodes: \n",
    //     sourceNode,
    //     targetNode,
    //     "\nlinks: \n",
    //     link,
    //     "\nLink length: \n",
    //     linkLength,

    //     "\nsource: \n",
    //     sourcePosition,
    //     "\ntarget: \n",
    //     targetPosition,
    //     "\nLink position: \n",
    //     linkPosition,
    //     "\nDirection: \n",
    //     direction,
    //     "\nDirection-target: \n",
    //     new THREE.Vector3().subVectors(targetPosition, direction),
    //     "\nDirection+source: \n",
    //     new THREE.Vector3().addVectors(sourcePosition, direction)
    //   );
    // }
    // Update link positions and rotations

    const dummyObject = new THREE.Object3D();
    state.nodes.forEach((node, i) => {
      // if (node.a_name === "M.699.MNI") {
      //   console.log("Node", node);
      // }
      // create a node in the matrix for each node
      dummyObject.position.set(node.x, node.y, node.z);
      dummyObject.updateMatrix();
      // Ensure the scale and rotation are reset (if needed)
      dummyObject.scale.set(1, 1, 1);
      dummyObject.rotation.set(0, 0, 0);
      // if (node.a_name === "M.699.MNI") {
      //   console.log(
      //     "Matrix before setMatrix\n",
      //     ...dummyObject.matrix.elements,
      //     "\nrotation now:\n",
      //     instanceMesh.rotation,
      //     "\nrotation instant:\n",
      //     ...instanceMesh.rotation
      //   );
      // }
      instanceMesh.setMatrixAt(i, dummyObject.matrix);
    });
    return () => {
      if (!state.graph) return;
      state.graph.pauseAnimation();
      if (graphRef.current) {
        (graphRef.current as any).innerHTML = ""; // Clean up the DOM element
      }
      state.graph.scene().remove(instanceMesh).remove(linksInstancedMesh);
    };
  }, [state.isDataInitialized]);

  useEffect(() => {
    console.log("Focused node", state.focusedNode);
    if (!state.font) return;
    console.log("Graph ref here");
    if (
      !graphRef ||
      state.graph === null ||
      !state.graph! ||
      state.graph === null
    ) {
      console.error("No graph ref found");
      return;
    }
    console.log("Graph ref here2", state.focusedNode, state.destinationNode);
    const updatedNodes = state.graph!.graphData().nodes.map(updateNode(state));
    state.graph!.graphData().nodes = updatedNodes;
    state.graph!.cameraPosition(
      deriveCameraPosition(state.focusedNode, state.destinationNode!), // new position
      deriveFocusPosition(state.focusedNode, state.destinationNode), // lookAt ({ x, y, z })
      1250 // ms transition duration);
    );
  }, [state.focusedNode, state.destinationNode]);

  useEffect(() => {
    console.log("Graph ref", graphRef?.current, state.graph);
    if (!graphRef || !graphRef.current || !state.graph) return;
    // graphRef.current.innerHTML = "";
    console.log("Graph ref2", graphRef.current);
    // Add the raycasting listener
    graphRef.current.addEventListener("mousemove", onMouseMove);
    console.log("state at origin of event listener", state);
    graphRef.current.addEventListener("click", onMouseClick);

    return () => {
      graphRef.current?.removeEventListener("mousemove", onMouseMove);
      graphRef.current?.removeEventListener("click", onMouseClick);
    };
  }, [state.graph?.graphData().nodes]);
  const selectedNode = state.focusedNode
    ? state.nodes.find((node) => node.id === state.focusedNode!.id)
    : undefined;
  const selectedDestinationNode = state.destinationNode
    ? state.nodes.find((node) => node.id === state.destinationNode!.id)
    : undefined;
  return (
    <div className="bg-crude" id="map-view-container" ref={containerRef}>
      <div className="Quantum-Container Title">{`${state.type} Map`}</div>
      <div className="Quantum-Container">
        <span className="text-sm">{`This Map contains all of the systems in the game and their
        ${state.type} links (<${state.type === "NPC Gate" ? "300" : "5"} Light Years distant). To search for a system, just enter the name of the system below and the map will focus on it.`}</span>
      </div>
      <div className="Quantum-Container Title">{`Data`}</div>
      <div className="Quantum-Container">
        <div className="flex flex-row py-1 w-full justify-between">
          <div
            className="flex flex-col px-1 w-full"
            style={{ justifyContent: "end " }}
          >
            {state.focusedNode && (
              <div className="flex flex-col text-xs">
                <span>{`System Name: ${state.focusedNode.a_name}`}</span>
                <span>{`System ID: ${state.focusedNode.id}`}</span>
                <span>{`System Coordinates: (${state.focusedNode.locationX},${state.focusedNode.locationY},${state.focusedNode.locationZ})`}</span>
              </div>
            )}
            <span className="text-sm py-2">Input System Name</span>
            {error !== null && (
              <span className="text-xs">
                Error searching for system. {error}
              </span>
            )}

            <EveInput
              inputType="string"
              fieldName=""
              defaultValue=""
              placeholder="System name - (e.g. Cydias)"
              onChange={(name: string | number | null) => {
                if (!name) {
                  dispatch({
                    type: MapActions.SET_FOCUSED_NODE,
                    payload: undefined,
                  });
                  return;
                }
                const node = state.nodesMap[name.toString().toLowerCase()];
                if (!node || !node.x || !node.y || !node.z) {
                  setError(`No solar system found with system name: ${name}`);
                  return;
                }
                setError(null);
                dispatch({ type: MapActions.SET_FOCUSED_NODE, payload: node });
              }}
            />
          </div>
          <div
            className="flex flex-col px-1 w-full"
            style={{ justifyContent: "end " }}
          >
            {state.destinationNode && selectedDestinationNode && (
              <div className="flex flex-col text-xs">
                <span>{`System Name: ${state.destinationNode.a_name}`}</span>
                <span>{`System ID: ${state.destinationNode.id}`}</span>
                <span>{`System Coordinates: (${state.destinationNode.locationX},${state.destinationNode.locationY},${state.destinationNode.locationZ})`}</span>
              </div>
            )}{" "}
            <span className="text-sm py-2">Input Destination System Name</span>
            {destError !== null && (
              <span className="text-xs">
                Error searching for system. {destError}
              </span>
            )}
            <EveInput
              inputType="string"
              fieldName=""
              defaultValue=""
              placeholder="Destination System name - (e.g. Cydias)"
              onChange={(name: string | number | null) => {
                if (!name) {
                  dispatch({
                    type: MapActions.SET_DESTINATION_NODE,
                    payload: undefined,
                  });
                  return;
                }
                const node = state.nodesMap[name.toString().toLowerCase()];
                console.log("selected dest node ", node);
                if (!node || !node.x || !node.y || !node.z) {
                  setDestError(
                    `No solar system found with system name: ${name}`
                  );
                  return;
                }
                setDestError(null);
                dispatch({
                  type: MapActions.SET_DESTINATION_NODE,
                  payload: node,
                });
              }}
            />
          </div>
        </div>
        {state.destinationNode && state.focusedNode && (
          <div className="flex flex-col text-sm">
            <span>{`Connection: ${state.focusedNode.a_name} => ${state.destinationNode.a_name}`}</span>
            <span>{`System ID: ${state.focusedNode.solarSystemId} => ${state.destinationNode.solarSystemId}`}</span>
            <span>{`Distance: ${lightYearsBetweenTwo3DPoints(
              {
                x: BigInt(state.focusedNode.locationX?.toString()!),
                y: BigInt(state.focusedNode.locationY?.toString()!),
                z: BigInt(state.focusedNode.locationZ?.toString()!),
              },
              {
                x: BigInt(state.destinationNode.locationX?.toString()!),
                y: BigInt(state.destinationNode.locationY?.toString()!),
                z: BigInt(state.destinationNode.locationZ?.toString()!),
              }
            ).toFixed(7)}LY`}</span>
          </div>
        )}
      </div>
      {graphRef ? (
        <div className="Quantum-Container">
          <div
            ref={graphRef as any}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              position: "relative",
            }}
          />
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default GalaxyMap;
