import { useMUD } from "../../MUDContext";
import React, { useEffect, useRef, useState } from "react";
import { EveButton, EveInput } from "@eveworld/ui-components";
import { getAssetBalance, converBigIntToDisplayNumber } from "../../utils";
import { PublicClient } from "viem";
import FloatingAnimatedNumber from "../FloatingAnimatedNumber";
import { get } from "cypress/types/lodash";

const ERC20Manager = function ManageErc20Token({
  smartAssemblyId,
  publicClient,
  tradeAssetDecimals,
}: {
  smartAssemblyId: bigint;
  publicClient: PublicClient;
  tradeAssetDecimals: number;
}) {
  const [itemTradeAddress, setItemTradeAddress] = useState<null | string>(null);
  const [orderbookDexContractAddress, setOrderbookDexContractAddress] =
    useState<null | string>(null);
  const [erc20TokenAddress, setErc20TokenAddress] = useState<
    string | undefined
  >();
  const [erc20Receiver, setErc20Receiver] = useState<string | undefined>();
  const [balances, setBalances] = React.useState<{
    playerBalance: bigint;
    ssuSystemBalance: bigint;
  }>({
    playerBalance: 0n,
    ssuSystemBalance: 0n,
  });
  const {
    network: { walletClient },
    systemCalls: {
      registerERC20Token,
      updateERC20Receiver,
      getERC20Data,
      collectTokens,
      getOrderbookDexContractAddress,
    },
  } = useMUD();
  const tradeAssetAddress = erc20TokenAddress;

  useEffect(() => {
    console.debug("tradeAssetAddress", tradeAssetAddress);
    // run balance lookup on initial load
    if (!walletClient?.account?.address) {
      throw new Error("No account address found");
    }
    if (!tradeAssetAddress) {
      console.debug("No trade asset address found");
      return;
    }
    Promise.all([
      getAssetBalance(
        walletClient?.account?.address,
        tradeAssetAddress,
        walletClient,
        publicClient,
        18
      ),
      getOrderbookDexContractAddress()
        .then(async (itemTradeContractAddress) => {
          setItemTradeAddress(itemTradeContractAddress);
          return await getAssetBalance(
            itemTradeContractAddress,
            tradeAssetAddress,
            walletClient,
            publicClient,
            tradeAssetDecimals
          )
            .then((d) => d)
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error("failed to get item trade contract address", error);
        }),
      getOrderbookDexContractAddress().then(setOrderbookDexContractAddress),
    ])
      .then(([userBalance, ssuBalance]) => {
        setBalances({
          ssuSystemBalance: ssuBalance || 0n,
          playerBalance: userBalance || 0n,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    const interval = setInterval(() => {
      if (!walletClient?.account?.address) {
        throw new Error("No account address found");
      }
      Promise.all([
        getAssetBalance(
          walletClient?.account?.address,
          tradeAssetAddress,
          walletClient,
          publicClient,
          tradeAssetDecimals
        ),
        getOrderbookDexContractAddress().then(
          async (itemTradeContractAddress) => {
            return await getAssetBalance(
              itemTradeContractAddress,
              tradeAssetAddress,
              walletClient,
              publicClient,
              tradeAssetDecimals
            )
              .then((d) => d)
              .catch((error) => {
                console.error(error);
              });
          }
        ),
      ])
        .then(([userBalance, ssuBalance]) => {
          setBalances({
            ssuSystemBalance: ssuBalance || 0n,
            playerBalance: userBalance || 0n,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }, 4000);
    return () => clearInterval(interval);
  }, [tradeAssetAddress]);

  const fetchErc20Data = async () => {
    const erc20TokenData = await getERC20Data(smartAssemblyId).then((d) => {
      console.debug(d);
      return d;
    });
    setErc20TokenAddress(erc20TokenData?.tokenAddress as string);
    setErc20Receiver(erc20TokenData?.receiver as string);
  };

  const erc20TokenAddressValueRef = useRef("");
  const erc20ReceiverValueRef = useRef("");

  const handleEdit = (
    refString: React.MutableRefObject<string>,
    eventString: string | number | null
  ): void => {
    refString.current = eventString?.toString() ?? "";
  };
  const playerBalance = converBigIntToDisplayNumber(
    balances.playerBalance,
    tradeAssetDecimals
  );
  const storageBalance = converBigIntToDisplayNumber(
    balances.ssuSystemBalance,
    tradeAssetDecimals
  );
  console.log("playerBalance", playerBalance);
  console.log("storageBalance", storageBalance);
  console.debug("smartAssemblyId", smartAssemblyId);
  return (
    <div className="bg-crude">
      <div className="flex flex-col">
        <div className="Quantum-Container Title">Current Data</div>
        <div className="flex flex-col Quantum-Container">
          Token contract address:{" "}
          <div className="px-2">
            {erc20TokenAddress ?? "Null. Try to `Fetch` first"}
          </div>
          Receiver address:{" "}
          <div className="px-2">
            {erc20Receiver ?? "Null. Try to `Fetch` first"}
          </div>
          ItemTrader System Adress:{" "}
          <div className="px-2">
            {itemTradeAddress ?? "Null. Try to `Fetch` first"}
          </div>
          OrderbookDex System Adress:{" "}
          <div className="px-2">
            {orderbookDexContractAddress ?? "Null. Try to `Fetch` first"}
          </div>
          Owner Balance
          <div className="px-2">
            <FloatingAnimatedNumber
              wholeNumber={parseInt(playerBalance.split(".")[0])}
              decimalWholeNumber={parseInt(playerBalance.split(".")[1])}
              duration={250}
            />
            <span className="px-2">EVE</span>
          </div>
          ItemTrader System Balance
          <div className="px-2">
            <FloatingAnimatedNumber
              wholeNumber={parseInt(storageBalance.split(".")[0])}
              decimalWholeNumber={parseInt(storageBalance.split(".")[1])}
              duration={250}
            />
            <span className="px-2">EVE</span>
          </div>
        </div>
      </div>
      <div className="Quantum-Container Title">Actions</div>
      <div className="flex flex-col my-1 p-2">
        <span className="font-bold">Fetch data</span>
        <EveButton
          className="mr-2"
          typeClass="tertiary"
          onClick={async (event) => {
            event.preventDefault();
            fetchErc20Data();
          }}
        >
          Fetch
        </EveButton>{" "}
      </div>
      <div className="flex flex-col my-1 p-2">
        <span className="font-bold">Sweep Tokens</span>
        <EveButton
          typeClass="tertiary"
          onClick={async (event) => {
            event.preventDefault();
            console.log(
              "tokens collected:",
              await collectTokens(
                smartAssemblyId,
                walletClient?.account?.address as `0x${string}`
              )
            );
          }}
        >
          Sweep Tokens
        </EveButton>
      </div>
      <div className="flex flex-col my-1 p-2">
        <span className="font-bold">Register ERC20 Token</span>

        <EveInput
          inputType="string"
          defaultValue={erc20TokenAddress}
          fieldName={"ERC20 token"}
          onChange={(str) => handleEdit(erc20TokenAddressValueRef, str)}
        />
        <EveButton
          typeClass="tertiary"
          onClick={async (event) => {
            event.preventDefault();
            await registerERC20Token(
              smartAssemblyId,
              erc20TokenAddressValueRef.current as `0x${string}`,
              walletClient.account?.address as `0x${string}`
            );
            fetchErc20Data();
          }}
        >
          Set ERC-20 Token
        </EveButton>
      </div>
      <div className="flex flex-col my-1 p-2">
        <span className="font-bold">Update Token Receiver</span>
        <EveInput
          inputType="string"
          defaultValue={erc20Receiver}
          fieldName={"Token receiver"}
          onChange={(str) => handleEdit(erc20ReceiverValueRef, str)}
        />
        <EveButton
          typeClass="tertiary"
          onClick={async (event) => {
            event.preventDefault();
            const erc20Receiver = await updateERC20Receiver(
              smartAssemblyId,
              erc20ReceiverValueRef.current as `0x${string}`
            );
            if (erc20Receiver) {
              console.log("receiver", erc20Receiver);
            }
          }}
        >
          Update ERC-20 Token Receiver
        </EveButton>
      </div>
    </div>
  );
};

export default ERC20Manager;
