import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import FloatingAnimatedNumber from "./FloatingAnimatedNumber";
import { deriveNumberParts } from "../utils/displayFormat";
import { DEFAULT_TOKEN_DECIMALS } from "@/constants";
import EveScroll from "@eveworld/ui-components/components/EveScroll";
import "./fadeIn.css";
import AnimatedNumber from "./AnimatedNumber";
import { useState } from "react";

interface ITablePropss {
  title: string;
  headers: Record<string, { title: string; style?: any }>;
  arrayOfRecords: any[];
  tradeAssetTicker?: string;
}

const formatValueToDisplay = (value: any, tradeAssetTicker?: string) => {
  switch (typeof value) {
    case "string": {
      return <p>{value}</p>;
    }
    // if value is object, it is assumed to be a date object
    case "object": {
      return (
        <p style={{ fontSize: "10px" }}>
          {value.toLocaleString().replace(",", " ")}
        </p>
      );
    }
    case "number": {
      // if value is float, then parse it to string and split it to get whole number and decimal
      const isInteger = value % 1 === 0;
      if (isInteger) {
        return <AnimatedNumber value={value} duration={1000} />;
      }
      const valueParts = value.toString().split(".");
      return (
        <FloatingAnimatedNumber
          wholeNumber={parseInt(valueParts[0])}
          decimalWholeNumber={parseInt(valueParts[1])}
          duration={1000}
        />
      );
    }
    case "bigint": {
      const valueParts = deriveNumberParts(value, DEFAULT_TOKEN_DECIMALS);
      return (
        <FloatingAnimatedNumber
          wholeNumber={parseInt(valueParts.wholeNumber)}
          decimalWholeNumber={parseInt(valueParts.decimal)}
          duration={1000}
          ticker={tradeAssetTicker}
        />
      );
    }
    default: {
      console.error(
        "Invalid type for value",
        value,
        " and typeof ",
        typeof value
      );
      return "unknown";
    }
  }
};

const FixedLengthTable = ({
  title,
  headers,
  arrayOfRecords,
  tradeAssetTicker,
}: ITablePropss) => {
  const [showTable, setShowTable] = useState(true);
  const headersToUse = Object.keys(headers);
  const toggleTable = () => {
    setShowTable(!showTable);
  };
  return (
    <>
      <div className="Quantum-Container Text font-bold">
        <button onClick={toggleTable} className="w-full">
          <div className="flex flex-row justify-between">
            <span>{title}</span>
            <span className="font-bold px-2">{showTable ? "-" : "+"}</span>
          </div>
        </button>
      </div>
      {showTable && (
        <EveScroll maxHeight="200px" style={{ minHeight: "200px" }} id="tablee">
          <div style={{ minHeight: "200px" }}>
            <Table>
              <TableHead>
                <tr className="flex flex-row w-full bg-brightquantum text-crude">
                  {headersToUse.map((idxKey, idx) => (
                    <th
                      className="border-crude border w-full px-2 text-left text-xs"
                      key={`tblheaders-${idxKey + "-" + idx}`}
                      style={headers[idxKey].style}
                    >
                      {headers[idxKey].title}
                    </th>
                  ))}
                </tr>
              </TableHead>

              {arrayOfRecords && arrayOfRecords.length > 0 ? (
                <TableBody>
                  {arrayOfRecords.map((record, idx) => (
                    <tr
                      className="flex flex-row w-full text-right text-s"
                      key={`tblRow-${idx}-${title}`}
                    >
                      {headersToUse.map((idxKey, idx) => (
                        <td
                          className="border-brightquantum border w-full px-1 text-xs"
                          key={`tblcell-${idxKey + "-" + idx}`}
                          style={headers[idxKey].style}
                        >
                          {formatValueToDisplay(
                            record[idxKey],
                            tradeAssetTicker
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <tr>
                    <td className="flex w-full h-full justify-center py-2">
                      No orders yet
                    </td>
                  </tr>
                </TableBody>
              )}
            </Table>
          </div>
        </EveScroll>
      )}
    </>
  );
};

export default FixedLengthTable;
