import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";

import { COLOR_THEME, SETTINGS } from "./constants";
import { CoordsWithMetaAndRelations } from "./types";
import { IMapState } from "./reducer";

export const DYNAMIC_SHADER_MATERIAL = new THREE.ShaderMaterial({
  vertexShader: `
    attribute vec3 instanceColor;
    varying vec3 vColor;

    void main() {
      vColor = instanceColor;
      vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
      gl_Position = projectionMatrix * mvPosition;
    }
  `,
  fragmentShader: `
    varying vec3 vColor;

    void main() {
      gl_FragColor = vec4(vColor, 1.0);
    }
  `,
});

export const updateNode =
  (state: IMapState) => (node: THREE.Object & CoordsWithMetaAndRelations) => {
    const obj = node.__threeObj;
    if (!node || !obj || !node.a_name) {
      console.log("ERROR WITH UPDATING DATA IN PLACE", node, obj, node.a_name);
      return;
    }
    // set destination node color red and add a halo
    if (state.destinationNode && node.id === state.destinationNode.id) {
      console.log(
        "Focused dest node",
        node,
        node.a_name,
        state.destinationNode
      );

      // override the node with a different colored sphere for emphasis
      const selectionSphere = new THREE.Mesh(
        new THREE.SphereGeometry(0.1, 32, 32),
        new THREE.MeshBasicMaterial({
          color: COLOR_THEME.selectedDestinationSystemColor,
          // transparent: true,
          opacity: COLOR_THEME.selectedDestinationSystemOpacity,
        })
      );
      console.log("new text geometry", node, node.a_name);
      const textGeometry = new TextGeometry(node.a_name, {
        font: state.font!,
        size: 0.05,
        height: 0.075,
        depth: 0.01,
      });

      const textMaterial = new THREE.MeshBasicMaterial({
        color: COLOR_THEME.selectedDestinationSystemColor,
        opacity: COLOR_THEME.selectedDestinationSystemOpacity,
      });
      const textMesh = new THREE.Mesh(textGeometry, textMaterial);
      textMesh.position.set(...SETTINGS.labelPosition); // Position above the sphere
      // Add an update callback to make the text always face the camera
      textMesh.onBeforeRender = (renderer, scene, camera) => {
        textMesh.quaternion.copy(camera.quaternion); // Align with camera orientation
      };
      textMesh.renderOrder = 500; // render text after the node
      selectionSphere.add(textMesh);
      // add a halo-like sphere around the focused node
      const haloSphere = new THREE.Mesh(
        new THREE.SphereGeometry(5, 64, 64),
        new THREE.MeshBasicMaterial({
          color: COLOR_THEME.selectedDestinationSystemHaloColor,
          transparent: true,
          opacity: COLOR_THEME.selectedDestinationSystemHaloOpacity,
        })
      );
      haloSphere.renderOrder = 1; // render halo sphere after the node
      // add the halo to the scene
      selectionSphere.add(haloSphere);
      obj.add(selectionSphere); // Return the sphere to attach it to the node
    } else if (
      state.focusedNode &&
      node.id !== state.focusedNode.id &&
      (node as CoordsWithMetaAndRelations).regionID ===
        state.focusedNode.regionID
    ) {
      // if we're in the same region, but not the same constellation, color the node differently
      const textGeometry = new TextGeometry(node.a_name, {
        font: state.font!,
        size: 0.05,
        height: 0.05,
        depth: 0.01,
      });

      const textMaterial = new THREE.MeshBasicMaterial({
        color: COLOR_THEME.selectedConstellationColor,
      });
      const textMesh = new THREE.Mesh(textGeometry, textMaterial);
      textMesh.position.set(...SETTINGS.labelPosition); // Position above the sphere
      // Add an update callback to make the text always face the camera
      textMesh.onBeforeRender = (renderer, scene, camera) => {
        // set the text to always face directly the camera. (Billboard)
        textMesh.quaternion.copy(camera.quaternion); // Align with camera orientation
      };
      textMesh.renderOrder = 500; // render text after the node
      obj.add(textMesh);
    } else if (state.focusedNode && node.id === state.focusedNode.id) {
      console.log("Focused src node", node, node.a_name);
      // override the node with a different colored sphere for emphasis
      const selectionSphere = new THREE.Mesh(
        new THREE.SphereGeometry(0.1, 32, 32),
        new THREE.MeshBasicMaterial({
          color: COLOR_THEME.selectedSystemColor,
          transparent: true,
          opacity: COLOR_THEME.selectedSystemOpacity,
        })
      );

      const textGeometry = new TextGeometry(node.a_name, {
        font: state.font!,
        size: 0.05,
        height: 0.075,
        depth: 0.01,
      });

      const textMaterial = new THREE.MeshBasicMaterial({
        color: COLOR_THEME.selectedSystemColor,
      });
      const textMesh = new THREE.Mesh(textGeometry, textMaterial);
      textMesh.position.set(...SETTINGS.labelPosition); // Position above the sphere
      // Add an update callback to make the text always face the camera
      textMesh.onBeforeRender = (renderer, scene, camera) => {
        textMesh.quaternion.copy(camera.quaternion); // Align with camera orientation
      };
      textMesh.renderOrder = 499; // render text after the node
      selectionSphere.add(textMesh);
      // add a halo-like sphere around the focused node
      const haloSphere = new THREE.Mesh(
        new THREE.SphereGeometry(5, 64, 64),
        new THREE.MeshBasicMaterial({
          color: COLOR_THEME.selectedSystemHaloColor,
          transparent: true,
          opacity: COLOR_THEME.selectedSystemHaloOpacity,
        })
      );
      haloSphere.renderOrder = 1; // render halo sphere after the node
      // add the halo to the scene
      selectionSphere.add(haloSphere);
      obj.add(selectionSphere); // Return the sphere to attach it to the node
    }
    node.__threeObj = obj;
    return node;
  };
