import React from "react";
const ErrorMsg = ({ message }: { message: string }) => {
  return (
    <div className="" style={{ color: "red", textAlign: "end" }}>
      {message}
    </div>
  );
};

export default ErrorMsg;
