import React from "react";
import memoize from "memoizee";
import { PublicClient, WalletClient } from "viem";
import { useNotification } from "@eveworld/contexts";

import UserStorage from "../EntityComponents/UserStorage";
import DeployableStorage from "../EntityComponents/DeployableStorage";

import { getAssetBalance, getErc20Balance, useEffectOnce } from "../../utils";
import BalanceModule from "../EntityComponents/BalanceModule";
import { useMUD } from "../../MUDContext";
import { Severity } from "@eveworld/types";
import Dex from "./Dex";

const DexModule = ({
  smartAssembly,
  walletClient,
  publicClient,
  characterId,
}: {
  smartAssembly: any;
  characterId: string;
  walletClient: WalletClient;
  publicClient: PublicClient;
}): JSX.Element => {
  const [balances, setBalances] = React.useState<{
    playerBalance: bigint;
    ssuSystemBalance: bigint;
  }>({
    playerBalance: 0n,
    ssuSystemBalance: 0n,
  });
  const {
    systemCalls: { getOrderbookDexContractAddress },
  } = useMUD();
  const { notify } = useNotification();
  const tradeAssetSymbol = "EVE";
  const tradeAssetAddress = import.meta.env.VITE_ERC20_TOKEN_ADDRESS || 
    "0x227517bfb7846827831744461fECb1234C66f65a".toLowerCase();
  
  const tradeAssetDecimals = 18;

  useEffectOnce(() => {
    console.debug("Smart Assembly", smartAssembly);
    // run balance lookup on initial load
    if (!walletClient?.account?.address) {
      throw new Error("No account address found");
    }
    Promise.all([
      getAssetBalance(
        walletClient?.account?.address,
        tradeAssetAddress,
        walletClient,
        publicClient,
        tradeAssetDecimals
      ),
      getOrderbookDexContractAddress()
        .then(async (itemTradeContractAddress) => {
          return await getAssetBalance(
            itemTradeContractAddress,
            tradeAssetAddress,
            walletClient,
            publicClient,
            tradeAssetDecimals
          )
            .then((d) => d)
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error("failed to get item trade contract address", error);
        }),
    ])
      .then(([userBalance, ssuBalance]) => {
        setBalances({
          ssuSystemBalance: ssuBalance || 0n,
          playerBalance: userBalance || 0n,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    const interval = setInterval(() => {
      if (!walletClient?.account?.address) {
        throw new Error("No account address found");
      }
      Promise.all([
        getAssetBalance(
          walletClient?.account?.address,
          tradeAssetAddress,
          walletClient,
          publicClient,
          tradeAssetDecimals
        ),
        getOrderbookDexContractAddress().then(
          async (itemTradeContractAddress) => {
            return await getAssetBalance(
              itemTradeContractAddress,
              tradeAssetAddress,
              walletClient,
              publicClient,
              tradeAssetDecimals
            )
              .then((d) => d)
              .catch((error) => {
                console.error(error);
              });
          }
        ),
      ])
        .then(([userBalance, ssuBalance]) => {
          setBalances({
            ssuSystemBalance: ssuBalance || 0n,
            playerBalance: userBalance || 0n,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }, 4000);
    return () => clearInterval(interval);
  });

  return (
    <div className="flex flex-col w-full">
      <div className="grid grid-cols-2 w-full" style={{ flex: 1 }}>
        <div className="flex">
          <Dex
            characterId={characterId}
            smartAssembly={smartAssembly}
            walletClient={walletClient}
            tradeAssetBalance={balances.playerBalance}
            tradeAssetDecimals={tradeAssetDecimals}
            tradeAssetTicker={tradeAssetSymbol}
          />
        </div>
      </div>
    </div>
  );
};

export default DexModule;
