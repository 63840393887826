import { useReducer, useState } from "react";
import { ChainConfig, Severity, type InventoryItem } from "@eveworld/types";
import { EveButton, EveInput } from "@eveworld/ui-components";
import { PublicClient, WalletClient } from "viem";
import { useNotification, useWorld } from "@eveworld/contexts";
import memoize from "memoizee";

import { TradingReducer, ITradingState, TradingActionType } from "./";
import { formatDisplayPrice } from "../../utils/displayFormat";
import { useEffectOnce } from "../../utils/hooks";
import ErrorMsg from "../ErrorMsg";
import FloatingAnimatedNumber from "../FloatingAnimatedNumber";
import { useMUD } from "../../MUDContext";
import Tooltip from "../ToolTip";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderType } from "@/types/orders";

export const composeInitialState = (
  itemId: number,
  itemName: string
): ITradingState => ({
  itemId: itemId,
  itemName: itemName,
  quantity: {
    quantityToTrade: 1,
    error: undefined,
  },
  price: {
    priceToTrade: 0n,
    error: undefined,
  },
  baseAssetTicker: "EVE",
  isTrading: true,
});

const StorageItem = ({
  walletClient,
  publicClient,
  defaultNetwork,
  assemblyId,
  item,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
  type = "none",
  disabledMsg,
}: {
  walletClient: WalletClient;
  publicClient: PublicClient | null;
  defaultNetwork: ChainConfig;
  assemblyId: bigint;
  item: any;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
  type: "purchase" | "sell" | "none";
  disabledMsg: string | null;
}) => {
  const initialState = composeInitialState(item.itemId, item.name);
  const [reducer, dispatch] = useReducer(TradingReducer, initialState);
  const { world } = useWorld();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    systemCalls: { getMarketPrice },
  } = useMUD();
  const { notify } = useNotification();
  const [trading, setTrading] = useState<boolean>(false);
  const { typeId, name, quantity } = item;

  const handleTradeClick = () => {
    console.debug(
      `Want to ${type} item '${item.typeId}' of quantity '${reducer.quantity.quantityToTrade}' for price '${reducer.price.priceToTrade}'.`
    );
    setTrading(true);
  };

  const navigateToItem = () => {
    const queryParams = new URLSearchParams(location.search);
    // add itemId to query params
    queryParams.set("itemId", item.itemId.toString());
    // change path to /dex/market
    const newPath = "/dex/market";
    const samePathNewQuery = `${newPath}?${queryParams.toString()}`;
    navigate(samePathNewQuery);
  };
  const fetchAndUpdatePrice = async (
    itemTypeId: string,
    assemblyId: bigint,
    type: "purchase" | "sell" | "none"
  ) => {
    const memoizedPriceFetch = memoize(
      (itemTypeId, assemblyId, type) => {
        if (type === "none") {
          console.debug("No need to fetch price, type is none.");
        }
        getMarketPrice(itemTypeId, assemblyId, OrderType.LIMIT_SELL)
          .then((price) => {
            console.debug("Price fetched from contract", price);
            if (price !== null) {
              dispatch({
                type: TradingActionType.SET_PRICE,
                payload: {
                  priceToTrade: price,
                  errors: undefined,
                },
              });
              return;
            }
          })
          .catch((e) => {
            console.error("error fetching price for ", itemTypeId, assemblyId);
            notify({ type: Severity.Error, message: e.message });
          });
      },
      { maxAge: 5000, promise: true }
    );
    return await memoizedPriceFetch(itemTypeId, assemblyId, type);
  };
  useEffectOnce(() => {
    fetchAndUpdatePrice(item.itemId, assemblyId, type);
    const interval = setInterval(() => {
      fetchAndUpdatePrice(item.itemId, assemblyId, type);
    }, 10000);
    return () => clearInterval(interval);
  });

  const handleQuantityChange = (value: string | number | null) => {
    if (value === null) {
      dispatch({
        type: TradingActionType.SET_QUANTITY,
        payload: {
          quantityToTrade: 0,
          error: "No Quantity Specified",
        },
      });
      return;
    }
    const derivedQuantity = typeof value === "number" ? value : parseInt(value);
    if (derivedQuantity === 0) {
      dispatch({
        type: TradingActionType.SET_QUANTITY,
        payload: {
          quantityToTrade: derivedQuantity,
          error: "Quantity cannot be 0",
        },
      });
      return;
    }
    if (derivedQuantity > item.quantity) {
      dispatch({
        type: TradingActionType.SET_QUANTITY,
        payload: {
          quantityToTrade: derivedQuantity,
          error: `Insufficient quantity available to ${type}`,
        },
      });
      return;
    }
    if (!reducer.price.priceToTrade) {
      dispatch({
        type: TradingActionType.SET_QUANTITY,
        payload: {
          quantityToTrade: derivedQuantity,
          error: "Unabled to fetch price for item.",
        },
      });
      return;
    }
    const derivedCostWei = BigInt(derivedQuantity) * reducer.price.priceToTrade;
    const derivedTradeAssetBalanceWei =
      tradeAssetBalance * BigInt(10) ** BigInt(tradeAssetDecimals);
    if (derivedCostWei > derivedTradeAssetBalanceWei) {
      dispatch({
        type: TradingActionType.SET_QUANTITY,
        payload: {
          quantityToTrade: derivedQuantity,
          error: "Insufficient balance.",
        },
      });
      return;
    }
    dispatch({
      type: TradingActionType.SET_QUANTITY,
      payload: { quantityToTrade: derivedQuantity, error: undefined },
    });
  };
  const someError = !!(reducer.quantity.error || reducer.price.error);
  const priceToDisplay = formatDisplayPrice(
    reducer.price.priceToTrade || 0n,
    18
  );
  const totalPriceToDisplay = formatDisplayPrice(
    (reducer.price.priceToTrade || 0n) *
      BigInt(reducer.quantity.quantityToTrade),
    18
  );
  const wholeNumber = parseInt(priceToDisplay.split(".")[0]);
  const decimalWholeNumber = parseInt(priceToDisplay.split(".")[1]);
  const totalWholeNumber = parseInt(totalPriceToDisplay.split(".")[0]);
  const totalDecimalWholeNumber = parseInt(totalPriceToDisplay.split(".")[1]);
  return (
    <div className="flex flex-col justify-between">
      <div className={`flex flex-row w-full`} style={{ justifyItems: "end" }}>
        <div className="flex flex-row w-full" style={{ gridColumn: "span 4" }}>
          {item.image ? (
            <img
              className="bg-[#381B0C]"
              style={{
                maxHeight: "40px",
                height: "40px",
                width: "40px",
              }}
              src={item.image.replace(
                "https://sandbox-garnet-ipfs-gateway.nursery.reitnorf.com/ipfs/",
                "https://mainnet-game-ipfs-gateway.nursery.reitnorf.com/ipfs/"
              )}
              alt={name}
            />
          ) : null}
          <div
            className="flex flex-col w-full px-2"
            style={{ gridColumn: "span 1" }}
          >
            <div className="flex w-full justify-between font-bold">
              <span>{name ?? `Item Type ${typeId}`}</span>
            </div>

            <span>Qty: {quantity}</span>
            <div className="flex flex-row">
              <span>Price: </span>
              <div className="px-2">
                <FloatingAnimatedNumber
                  wholeNumber={wholeNumber}
                  decimalWholeNumber={decimalWholeNumber}
                  duration={250}
                />
              </div>
              <span>{tradeAssetTicker}</span>
            </div>
          </div>
        </div>
        <div style={{ maxWidth: "80px", gridColumn: "span 1" }}>
          {type !== "none" && (
            <Tooltip
              text={type === "purchase" ? "Buy item(s)" : "Sell item(s)"}
            >
              <EveButton
                typeClass="tertiary"
                className="font-normal"
                onClick={navigateToItem}
              >
                Sell
              </EveButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default StorageItem;
