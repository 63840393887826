import axios, { AxiosInstance } from "axios";
import memoize from "memoizee";
// import dotenv from "dotenv";
// dotenv.config();

export default class GatewayHTTPService {
  private static instance: GatewayHTTPService;
  private isInitialized: boolean = false;
  private client?: AxiosInstance;
  private defaultBaseURL: string =
    "https://blockchain-gateway-nova.nursery.reitnorf.com";

  private constructor() {
    this.initialize();
  }

  public static getInstance(): GatewayHTTPService {
    if (!GatewayHTTPService.instance) {
      GatewayHTTPService.instance = new GatewayHTTPService();
    }
    return GatewayHTTPService.instance;
  }

  private initialize = () => {
    if (this.isInitialized && this.client) return;
    this.isInitialized = true;
    console.warn(
      "Initializing GatewayHTTPService with baseUrl: ",
      this.defaultBaseURL
    );
    console.log(import.meta.env.VITE_GATEWAY_HTTP);
    this.client = axios.create({
      baseURL: import.meta.env.VITE_GATEWAY_HTTP || this.defaultBaseURL,
      timeout: 5000,
    });
  };

  public getClient = (): AxiosInstance => {
    if (!this.isInitialized || !this.client) {
      this.initialize();
    }
    return this.client as AxiosInstance;
  };

  private memoizedTypeFetch = memoize(
    async (typeId: bigint) => {
      return await this.getClient().get(`/types/${typeId.toString()}`);
    },
    {
      maxAge: 10 * 1000,
      promise: true,
    }
  );

  public getTypeMetadata = async (typeId: bigint) => {
    if (!this.isInitialized || !this.client) {
      this.initialize();
    }
    return await this.memoizedTypeFetch(typeId);
  };

  private memoizedSmartAssembliesFetch = memoize(
    async () => {
      return await this.getClient().get(`/smartassemblies`);
    },
    {
      maxAge: 10 * 1000,
      promise: true,
    }
  );

  public getSmartAssemblies = async () => {
    if (!this.isInitialized || !this.client) {
      this.initialize();
    }
    return await this.memoizedSmartAssembliesFetch();
  };

  public getSmartAssembliesInSystems = async (systems: string[]) => {
    const systemsMap: Map<number, boolean> = new Map();
    systems.forEach((system) => {
      systemsMap.set(parseInt(system), true);
    });
    return await this.getSmartAssemblies().then((res) => {
      const d = res.data.filter((sa: any) => {
        return (
          systemsMap.has(sa.solarSystem?.solarSystemId) &&
          sa.assemblyType === "SmartStorageUnit"
        );
      });
      // console.log("d", d);
      return d;
    });
  };
}