import ClickToCopy from "@eveworld/ui-components/components/ClickToCopy";
import EveLoadingAnimation from "@eveworld/ui-components/components/EveLoadingAnimation";
import { abbreviateAddress } from "@eveworld/utils/utils";
import React, { useState } from "react";
import SmartAssemblyActions from "../SmartAssemblyActions";
import SmartAssemblyInfo from "@eveworld/ui-components/components/SmartAssemblyInfo";

const AdminPanel = ({
  smartAssembly,
  smartCharacter,
  defaultNetwork,
  gatewayConfig,
  walletClient,
  publicClient,
  children,
}: any) => {
  const [openAbout, setOpenAbout] = useState(false);
  //   const defaultImages: Record<string, string> = {
  //     SmartStorageUnit: SmartStorageUnitImage,
  //     SmartTurret: SmartTurretImage,
  //     SmartGate: SmartGateImage,
  //   };

  const toggleConfig = () => setOpenAbout(!openAbout);
  return (
    <EveLoadingAnimation position="diagonal">
      <div className="grid border border-brightquantum bg-crude">
        <div
          className="flex flex-col align-center border border-brightquantum"
          id="smartassembly-name"
        >
          <div className="bg-brightquantum text-crude flex items-stretch justify-between px-2 py-1 font-semibold">
            <span className="uppercase">{smartAssembly?.name}</span>
            <span className="flex flex-row items-center">
              {abbreviateAddress(smartAssembly?.id)}
              <ClickToCopy
                text={smartAssembly?.id}
                className="text-crude"
              />{" "}
            </span>
          </div>
          {/* <img
            src={defaultImages[smartAssembly.assemblyType]}
            id="smartassembly-image"
          /> */}
          <SmartAssemblyActions />
        </div>
        {openAbout ?? (
          <div className="contents empty-span-full" onClick={toggleConfig}>
            <SmartAssemblyInfo
              smartAssembly={smartAssembly}
              smartCharacter={smartCharacter}
              defaultNetwork={defaultNetwork}
              gatewayConfig={gatewayConfig}
            />
          </div>
        )}
      </div>
      <div>{children}</div>
    </EveLoadingAnimation>
  );
};
export default AdminPanel;
