import { CoordsWithMeta } from "@/components/Map/types";
import { getCoords } from "@/components/Map/utils";
import { DEFAULT_RELATIVE_DISTANCE_IN_LY } from "@/constants";
import memoize from "memoizee";

export const distanceBetweenTwo3DPoints = (
  point1: { x: bigint; y: bigint; z: bigint },
  point2: { x: bigint; y: bigint; z: bigint }
) => {
  const dx = point1.x < point2.x ? point2.x - point1.x : point1.x - point2.x;
  const dy = point1.y < point2.y ? point2.y - point1.y : point1.y - point2.y;
  const dz = point1.z < point2.z ? point2.z - point1.z : point1.z - point2.z;
  const distanceSquared = dx ** 2n + dy ** 2n + dz ** 2n;

  // Calculate exact integer square root
  const distance = Math.sqrt(Number(distanceSquared));
  //   console.log("DISTANCE BETWEEN TWO 3D POINTS", distance, point1, point2);
  return distance;
};

export const lightYearsBetweenTwo3DPoints = (
  point1: { x: bigint; y: bigint; z: bigint },
  point2: { x: bigint; y: bigint; z: bigint }
) => {
  const distance = distanceBetweenTwo3DPoints(point1, point2);
  const lightYears = distance / 9.46073047e15;
  return lightYears;
};

export const distanceBetweenTwoPoints = (
  point1: { x: number; y: number; z: number },
  point2: { x: number; y: number; z: number }
) => {
  const dx = point1.x < point2.x ? point2.x - point1.x : point1.x - point2.x;
  const dy = point1.y < point2.y ? point2.y - point1.y : point1.y - point2.y;
  const dz = point1.z < point2.z ? point2.z - point1.z : point1.z - point2.z;
  const distanceSquared = dx ** 2 + dy ** 2 + dz ** 2;
  const distance = Math.sqrt(distanceSquared);
  return distance;
};

const memoizedSearchSolarSystemId = memoize(
  (solarSystemId) => {
    const { nodes } = getCoords();
    const map = nodes.reduce(
      (acc, current) => {
        acc[current.solarSystemId.toString()] = current;
        return acc;
      },
      {} as Record<string, CoordsWithMeta>
    );
    return map[solarSystemId.toString()];
  },
  { maxAge: 60 * 1000, promise: false }
);

export const getSolarSystemById = (solarSystemId: bigint) => {
  return memoizedSearchSolarSystemId(solarSystemId);
};

export const getNumberCoordsAsBigInt = (
  coords:
    | {
        x: number;
        y: number;
        z: number;
      }
    | { locationX: number; locationY: number; locationZ: number }
) => {
  return {
    x:
      "x" in coords
        ? BigInt(coords.x)
        : BigInt(coords.locationX.toString().split(".")[0]),
    y:
      "y" in coords
        ? BigInt(coords.y)
        : BigInt(coords.locationY.toString().split(".")[0]),
    z:
      "z" in coords
        ? BigInt(coords.z)
        : BigInt(coords.locationZ.toString().split(".")[0]),
  };
};

export const getAllSolarSystemIdsWithinXLY = (
  originSolarSystemId: bigint,
  distanceInLY: number = DEFAULT_RELATIVE_DISTANCE_IN_LY
) => {
  console.time("getAllSolarSystemIdsWithinXLY");
  const { nodes } = getCoords();
  const originSolarSystem = getSolarSystemById(originSolarSystemId);
  if (!originSolarSystem) {
    console.error("No solar system found for id", originSolarSystemId);
    return [];
  }
  console.debug("originSolarSystem", originSolarSystem);
  const allSolarSystemIds = nodes
    .map((coord) =>
      coord.solarSystemId ? BigInt(coord.solarSystemId) : undefined
    )
    .filter(Boolean) as bigint[];

  const solarSystemIdsWithinXLY = allSolarSystemIds.filter((solarSystemId) => {
    const solarSystem = getSolarSystemById(solarSystemId);

    // console.log("getNumberCoordsAsBigInt", originSolarSystem, solarSystem);
    const distance = lightYearsBetweenTwo3DPoints(
      getNumberCoordsAsBigInt(originSolarSystem),
      getNumberCoordsAsBigInt(solarSystem)
    );
    return distance <= distanceInLY;
  });
  console.timeEnd("getAllSolarSystemIdsWithinXLY");

  return solarSystemIdsWithinXLY;
};
