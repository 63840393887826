import endeavorAgreement from "../assets/Endeavor Trade Consortium 122cbdd26f01800c8454fb668749715c.html?raw";

const AgreementView = () => {
  return (
    <div className="bg-crude h-full py-2 flex flex-col align-center items-center">
      {endeavorAgreement && (
        <iframe src={endeavorAgreement} className="p-8" width="100%"></iframe>
      )}
    </div>
  );
};
export default AgreementView;
