const Loading = () => {
  return (
    <div className="flex justify-center">
      <div className="p-2 w-half align-center justify-center">
        {/* <EveLoadingAnimation position="diagonal"> */}
        <div className="flex w-full align-center justify-center p-2">
          Loading...
        </div>
        {/* </EveLoadingAnimation> */}
      </div>
    </div>
  );
};
export default Loading;
