import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSmartObject } from "@eveworld/contexts";
import {
  ClickToCopy,
  ErrorNotice,
  ErrorNoticeTypes,
  EveLoadingAnimation,
} from "@eveworld/ui-components";
import { abbreviateAddress } from "@eveworld/utils/utils";

import SmartGateImage from "../assets/smart-gate.png";
import SmartStorageUnitImage from "../assets/smart-storage-unit.png";
import SmartTurretImage from "../assets/smart-turret.png";
import Loading from "./DexSSU/views/Market/Loading";

const AllEntitiesView = () => {
  const { smartAssembly, smartCharacter, loading } = useSmartObject() as any;
  const location = useLocation();
  const navigate = useNavigate();
  const defaultImages: Record<string, string> = {
    SmartStorageUnit: SmartStorageUnitImage,
    SmartTurret: SmartTurretImage,
    SmartGate: SmartGateImage,
  };

  if (!smartCharacter || !smartCharacter.isSmartCharacter) {
    return (
      <EveLoadingAnimation position="diagonal">
        <div>
          <ErrorNotice
            loading={loading}
            smartAssembly={smartAssembly}
            type={ErrorNoticeTypes.MESSAGE}
            errorMessage={`The account you connected with is an invalid Smart Character.
          Please connect with a valid Smart Character account, or if you believe this message is in error
          - first try disconnecting then try to reach out to the #builder-help channel in EVE Frontier Discord.`}
          />
        </div>
      </EveLoadingAnimation>
    );
  }
  const addQueryParamsAndNav = (newParams: Record<string, string>) => {
    // Create a URLSearchParams object based on the current search string
    const searchParams = new URLSearchParams(location.search);

    // Add new query params
    for (const key in newParams) {
      searchParams.set(key, newParams[key]);
    }
    
    // Navigate to the same route with the updated search params
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  return (
    <>
      <EveLoadingAnimation position="diagonal">
        <div className="grid border border-brightquantum bg-crude">
          <div
            className="flex flex-col align-center border border-brightquantum"
            id="smartassembly-name"
          >
            <div className="bg-brightquantum text-crude flex items-stretch justify-between px-2 py-1 font-semibold">
              All Deployables
            </div>
          </div>
        </div>
        <div>
          {smartCharacter &&
          smartCharacter?.smartAssemblies &&
          smartCharacter?.smartAssemblies?.filter(
            (item: any) => ![0, 1].includes(item.stateId)
          ).length > 0 ? ( // @todo: fix this type as any
            smartCharacter?.smartAssemblies
              ?.filter((item: any) => ![0, 1].includes(item.stateId))
              .map((item: any, idx: number) => (
                <div
                  key={`item-entities-${idx}`}
                  className="flex flex-row align-center border items-stretch border-brightquantum"
                  id="smartassembly-name"
                  onClick={() =>
                    addQueryParamsAndNav({ smartObjectId: item.id })
                  }
                >
                  <img
                    height="64px"
                    width="128px"
                    src={defaultImages[item.assemblyType]}
                    id="smartassembly-image"
                  />
                  <div className="flex flex-col items-stretch justify-between px-2 py-1 flex-grow flex-shrink">
                    <div className="bg-crude flex flex-row items-stretch space-between px-2 py-1 font-normal text-xs flex-grow flex-shrink">
                      <span
                        className="flex-grow flex-shrink"
                        style={{ flex: 1, justifyContent: "end" }}
                      >
                        Name:
                      </span>
                      <span
                        className="flex"
                        style={{ flex: 1, justifyContent: "end" }}
                      >
                        {item?.name || "Unknown"}
                      </span>
                    </div>
                    {item?.id && (
                      <div className="bg-crude flex flex-row items-stretch space-between px-2 py-1 font-normal text-xs flex-grow flex-shrink">
                        <span className="flex-grow flex-shrink">
                          Assembly ID:
                        </span>
                        <span
                          className="flex flex-grow flex-shrink"
                          style={{ flex: 1, justifyContent: "end" }}
                        >
                          {abbreviateAddress(item?.id)}
                          <ClickToCopy text={item?.id} className="flex" />
                        </span>
                      </div>
                    )}
                    {item?.assemblyType && (
                      <div className="bg-crude flex flex-row items-stretch space-between px-2 py-1 font-normal text-xs flex-grow flex-shrink">
                        <span className="flex-grow flex-shrink">
                          Assembly Type:
                        </span>
                        <span
                          className="flex"
                          style={{ flex: 1, justifyContent: "end" }}
                        >
                          {item?.assemblyType}
                        </span>
                      </div>
                    )}
                    {item?.solarSystem?.solarSystemName && (
                      <div className="bg-crude flex flex-row items-stretch space-between px-2 py-1 font-normal text-xs flex-grow flex-shrink">
                        <span className="flex-grow flex-shrink">System:</span>
                        <span
                          className="flex"
                          style={{ flex: 1, justifyContent: "end" }}
                        >
                          {item?.solarSystem?.solarSystemName}
                        </span>
                      </div>
                    )}
                    {[true, false].includes(item?.isOnline) && (
                      <div className="bg-crude flex flex-row items-stretch space-between px-2 py-1 font-normal text-xs flex-grow flex-shrink">
                        <span className="flex-grow flex-shrink">Status:</span>
                        <span
                          className="flex"
                          style={{ flex: 1, justifyContent: "end" }}
                        >
                          {item.state}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <div className="flex center justify-center">
              No Assemblies Found for User
            </div>
          )}
        </div>
      </EveLoadingAnimation>
    </>
  );
};
export default AllEntitiesView;
