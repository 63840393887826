export const convertFloatToBigInt = (
  value: number,
  decimals: number
): bigint => {
  const [integerPart, fractionalPart = ""] = value.toString().split(".");

  // Convert integer part to BigInt and shift by decimals
  let bigIntValue = BigInt(integerPart) * BigInt(10 ** decimals);

  // Adjust for fractional part, if it exists
  if (fractionalPart.length > 0) {
    const fractionalLength = Math.min(fractionalPart.length, decimals);
    const fractionalDigits = fractionalPart.slice(0, fractionalLength);
    bigIntValue +=
      BigInt(fractionalDigits) * BigInt(10 ** (decimals - fractionalLength));
  }

  return bigIntValue;
};
