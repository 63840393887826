import BaseSSU from "./BaseSSU";
import TradingSSU from "../TradingSSU/TradingSSU";
import AdminPanel from "./AdminPanel";
import TradingAdminPanel from "../TradingSSU/TradingAdminPanel";
import DexSSU from "../DexSSU/DexSSU";

export const deriveEntityView = (
  a: "SmartStorageUnit" | "SmartGate" | "SmartTurret"
): { admin: any; user: any } => {
  switch (a) {
    case "SmartStorageUnit":
      return { admin: TradingAdminPanel, user: DexSSU };
    default:
      return { admin: AdminPanel, user: BaseSSU };
  }
};
