import { DexView, IViewOption } from "./types";
import { navOptions } from "./constants";
import { useLocation, useNavigate } from "react-router-dom";

interface IDexNav {
  view: DexView;
  changeView: (view: DexView) => void;
}

const DexNav = ({ view, changeView }: IDexNav) => {
  const navigate = useNavigate();
  const location = useLocation();  
  return (
    <div className="flex flex-row w-full p-2 justify-between">
      {navOptions.map((option: IViewOption, idx) => (
        <div
          className={`
            cursor-pointer p-2 text-center rounded-md text-s
            ${view === option.view ? "bg-brightquantum text-crude" : "bg-crude text-brightquantum border border-brightquantum"}
          `}
          style={{ minWidth: "150px" }}
          key={`dex-nav-${idx}`}
          onClick={() => {
            const queryParams = new URLSearchParams(location.search);
            const path = option.path;
            const newLoc = `${path}?${queryParams.toString()}`;
            navigate(newLoc);
          }}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default DexNav;
