import type { FunctionComponent, ReactNode } from "react";
import type { Config } from "react-popper-tooltip";
import { usePopperTooltip } from "react-popper-tooltip";

export interface TooltipProps {
  text: ReactNode;
  disabled?: boolean;
  fill?: string;
  placement?: Config["placement"];
  config?: Config;
  children: ReactNode;
}

export const Tooltip: FunctionComponent<TooltipProps> = ({
  text,
  fill = "black",
  placement = "top",
  children,
  config,
  disabled,
  ...props
}: any) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement, ...config });
  const { style, ...nonStyleProps } = getTooltipProps();
  const { style: arrowStyles, ...nonStyleArrowProps } = getArrowProps({
    className: "arrow",
  });
  const { style: propsStyles, ...otherProps } = props;
  return (
    <>
      <div
        ref={setTriggerRef}
        style={{ ...propsStyles, display: "inline-block" }}
        {...otherProps}
      >
        {children}
      </div>

      {visible && !disabled && (
        <div
          className="flex flex-col px-2 py-1 border-crude border bg-brightquantum text-crude text-sm font-bold"
          ref={setTooltipRef}
          style={{
            zIndex: "9999",
            ...style,
          }}
          {...nonStyleProps}
        >
          {typeof text === "string" ? <div>{text}</div> : text}
          <div
            className="tooltip-arrow"
            style={{
              pointerEvents: "none",
              ...arrowStyles,
            }}
            {...nonStyleArrowProps}
          />
        </div>
      )}
    </>
  );
};

export default Tooltip;
