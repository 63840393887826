export enum OrderType {
  LIMIT_BUY = 0,
  LIMIT_SELL = 1,
  MARKET_BUY = 2,
  MARKET_SELL = 3,
}
export enum OrderStatus {
  OPEN = 0,
  FILLED = 1,
  CANCELLED = 2,
}

export interface SavedOrder {
  orderId: bigint;
  smartObjectId: bigint;
  itemId: bigint;
  price: bigint;
  priceWithFee: bigint;
  traderCharacterId: bigint;
  tokenAddress: string;
  startingQuantity: bigint;
  orderStatus: number;
  orderType: number;
  quantityRemaining: bigint;
  createdTime: bigint;
  finishedTime: bigint;
}

export interface SavedOrderStatus {
  orderId: number;
  orderStatus: number;
  createdTime: number;
  quantityRemaining: number;
}
