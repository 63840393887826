export enum DexView {
  EPHEMERAL_INVENTORY = "EPHEMERAL_INVENTORY",
  YOUR_ORDERS = "YOUR_ORDERS",
  MARKETPLACE = "MARKETPLACE",
  INDIVIDUAL_ITEM_ORDERS = "INDIVIDUAL_ITEM_ORDERS",
  TRADE = "TRADE",
}
/**
 * Dex state needs to allow for functionality of a few different things:
 * 1. Displaying the users ephemeral inventory balances.
 * 2. Displaying the users open orders.
 * 3. Displaying the users closed orders.
 * 4. Displaying all known items - with filtering on all items available for trade.
 * 5. Displaying a specific item's open orders (allow for filtering on )

*/
export interface IDexState {
  viewToShow: DexView;
}

export interface IViewOption {
  label: string;
  view: DexView;
  path: string;
}
