import React, { useState, useEffect } from "react";
import { default as AnimatedNumber } from "./AnimatedNumber";
import { DEFAULT_DECIMAL_PLACES_TO_DISPLAY } from "@/constants";

const FloatingAnimatedNumber = ({
  wholeNumber,
  decimalWholeNumber,
  duration,
  ticker,
  decimalPlacesToDisplay = DEFAULT_DECIMAL_PLACES_TO_DISPLAY,
  isPercentage = false,
  style,
  tst = false,
}: {
  wholeNumber: number;
  decimalWholeNumber: number;
  duration: number;
  ticker?: string;
  decimalPlacesToDisplay?: number;
  isPercentage?: boolean;
  style?: any;
  tst?: boolean;
}) => {
  const [values, setValues] = useState({
    wholeNumber: wholeNumber,
    decimalWholeNumber: decimalWholeNumber,
  });

  useEffect(() => {
    setValues({
      wholeNumber,
      decimalWholeNumber,
    });
  }, [wholeNumber, decimalWholeNumber, duration]); // Trigger animation on value change
  if (tst) {
    console.log("FloatingAnimatedNumber", values);
  }
  return (
    <div style={{ ...style }}>
      {isPercentage && `(`}
      <AnimatedNumber value={values.wholeNumber} duration={duration} />.
      <AnimatedNumber
        value={values.decimalWholeNumber}
        duration={duration}
        isFloatingDecimals={true}
        decimalPlacesToDisplay={decimalPlacesToDisplay}
      />
      {ticker && <span className="pl-1">{ticker}</span>}
      {isPercentage && `%)`}
    </div>
  );
};
export default FloatingAnimatedNumber;
