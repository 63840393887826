import { useState } from "react";
import MarketItem from "./MarketItem";
import { IFullMarketState } from "./types";
import { EveButton } from "@eveworld/ui-components";

const GroupDisplay = ({
  fullMarketState,
  selectItem,
  groupMap,
}: {
  fullMarketState: IFullMarketState;
  selectItem: (item: bigint) => void;
  groupMap: Map<string, bigint[]>;
}) => {
  const [showGroup, setShowGroup] = useState(true);
  const toggleGroup = () => setShowGroup(!showGroup);
  return (
    <div className="flex flex-col flex-wrap pl-2 w-full">
      {Array.from(groupMap.entries()).map(([groupId, itemIds]) => {
        return (
          <div className="flex flex-col pl-2 py-1" key={`group-id-${groupId}`}>
            <EveButton
              typeClass="tertiary"
              className="Title bg-quantum flex flex-row justify-between w-full"
              onClick={toggleGroup}
            >
              <div className="flex flex-row justify-between w-full">
                <span>{groupId}</span>{" "}
                <span className="font-bold text-md">
                  {showGroup ? "-" : "+"}
                </span>
              </div>
            </EveButton>
            {showGroup && (
              <div className="flex flex-col pl-2 py-1 ">
                {itemIds.length > 0 &&
                  Array.from(new Set(itemIds)).map((itemId) => {
                    const item = fullMarketState.itemMeta.get(itemId);
                    if (!item) return;
                    return (
                      <div className="pl-2" key={`individualItem-${itemId}`}>
                        <MarketItem item={item} selectItem={selectItem} />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default GroupDisplay;
