import {
  DEFAULT_IPFS_GATEWAY,
  DEFAULT_RELATIVE_DISTANCE_IN_LY,
  DEFAULT_SOLAR_SYSTEMS_IPFS_CID,
} from "@/constants";
import GatewayHTTPService from "@/services/GatewayHTTPService";

import memoize from "memoizee";

const memoGetAllSolarSystemsInRange = (
  originSolarSystemId: number,
  range: number = DEFAULT_RELATIVE_DISTANCE_IN_LY
) =>
  memoize(
    async () => {
      const res = await fetch(
        `${DEFAULT_IPFS_GATEWAY}${DEFAULT_SOLAR_SYSTEMS_IPFS_CID}/${originSolarSystemId}.json`
      );
      return res.json().then((data) => {
        return data[0].inRange.filter((connection: any) => {
          return connection.distance <= range;
        });
      });
    },
    { maxAge: 60 * 1000, promise: true }
  );

export const getAllSolarSystemsInRange = async (
  originSolarSystemId: number,
  range: number = DEFAULT_RELATIVE_DISTANCE_IN_LY
) => {
  return memoGetAllSolarSystemsInRange(originSolarSystemId, range)();
};

export const getSSUsInRange = async (
  originSolarSystemId: number,
  range: number = DEFAULT_RELATIVE_DISTANCE_IN_LY
) => {
  const solarSystemsInRange: { toSystem: string; distance: number }[] =
    await getAllSolarSystemsInRange(originSolarSystemId, range);
  // console.log("solarSystemsInRange", solarSystemsInRange);
  return await GatewayHTTPService.getInstance().getSmartAssembliesInSystems(
    solarSystemsInRange.map((ss: any) => ss.toSystem)
  );
};
