import { DexView, IViewOption } from "./types";

export const navOptions: IViewOption[] = [
  {
    label: "My Orders",
    view: DexView.YOUR_ORDERS,
    path: "/dex/orders",
  },
  {
    label: "My Inventory",
    view: DexView.EPHEMERAL_INVENTORY,
    path: "/dex/inventory",
  },
  {
    label: "Market",
    view: DexView.MARKETPLACE,
    path: "/dex/market",
  },
  // {
  //   label: "Trade",
  //   view: DexView.TRADE,
  // },
];
