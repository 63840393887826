import { useReducer, useState } from "react";
import { ChainConfig, Severity, type InventoryItem } from "@eveworld/types";
// import { EveButton, EveInput } from "@eveworld/ui-components";
import { PublicClient, WalletClient } from "viem";
import { useNotification, useWorld } from "@eveworld/contexts";
// import memoize from "memoizee";

// import { TradingReducer, ITradingState, TradingActionType } from "./";
import { formatDisplayPrice } from "@/utils/displayFormat";
import { useMUD } from "@/MUDContext";
import { IItemMarketState } from "./singleItemMarket.reducer";
import FloatingAnimatedNumber from "@/components/FloatingAnimatedNumber";
import AnimatedNumber from "@/components/AnimatedNumber";
import Loading from "./Loading";

// export const composeInitialState = (
//   itemId: number,
//   itemName: string
// ): ITradingState => ({
//   itemId: itemId,
//   itemName: itemName,
//   quantity: {
//     quantityToTrade: 1,
//     error: undefined,
//   },
//   price: {
//     priceToTrade: 0n,
//     error: undefined,
//   },
//   baseAssetTicker: "EVE",
//   isTrading: true,
// });

const DexStorageItem = ({
  state,
  item,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
  //   type = "none",
  //   disabledMsg,
}: {
  state: IItemMarketState;
  item: any;
  // smartAssembly: any;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
  //   type: "purchase" | "sell" | "none";
  //   disabledMsg: string | null;
}) => {
  //   const initialState = composeInitialState(item.itemId, item.name);
  //   const [reducer, dispatch] = useReducer(TradingReducer, initialState);

  //   const { notify } = useNotification();
  //   const [showTradeWindow, setShowTradeWindow] = useState<boolean>(false);
  //   const [trading, setTrading] = useState<boolean>(false);
  //   const handleTradeClick = () => {
  //     console.debug(
  //       `Want to ${type} item '${item.typeId}' of quantity '${reducer.quantity.quantityToTrade}' for price '${reducer.price.priceToTrade}'.`
  //     );
  //     setTrading(true);
  //     handleTrade(type);
  //   };
  //   const fetchAndUpdatePrice = async (
  //     itemTypeId: string,
  //     assemblyId: bigint,
  //     type: "purchase" | "sell" | "none"
  //   ) => {
  //     const memoizedPriceFetch = memoize(
  //       (itemTypeId, assemblyId, type) => {
  //         if (type === "none") {
  //           console.debug("No need to fetch price, type is none.");
  //         }
  //         getItemPrice(itemTypeId, assemblyId, type)
  //           .then((price) => {
  //             // console.debug("Price fetched from contract", price);
  //             if (price !== null) {
  //               dispatch({
  //                 type: TradingActionType.SET_PRICE,
  //                 payload: {
  //                   priceToTrade: price,
  //                   errors: undefined,
  //                 },
  //               });
  //               return;
  //             }
  //           })
  //           .catch((e) => {
  //             console.error("error fetching price for ", itemTypeId, assemblyId);
  //             notify({ type: Severity.Error, message: e.message });
  //           });
  //       },
  //       { maxAge: 5000, promise: true }
  //     );
  //     return await memoizedPriceFetch(itemTypeId, assemblyId, type);
  //   };
  //   useEffectOnce(() => {
  //     fetchAndUpdatePrice(item.itemId, assemblyId, type);
  //     const interval = setInterval(() => {
  //       fetchAndUpdatePrice(item.itemId, assemblyId, type);
  //     }, 10000);
  //     return () => clearInterval(interval);
  //   });

  //   const someError = !!(reducer.quantity.error || reducer.price.error);
  //   const priceToDisplay = formatDisplayPrice(
  //     reducer.price.priceToTrade || 0n,
  //     18
  //   );
  //   const totalPriceToDisplay = formatDisplayPrice(
  //     (reducer.price.priceToTrade || 0n) *
  //       BigInt(reducer.quantity.quantityToTrade),
  //     18
  //   );

  const priceToDisplay = state.marketPrice
    ? formatDisplayPrice(state.marketPrice, 18)
    : "0.00";
  const wholeNumber = parseInt(priceToDisplay.split(".")[0]);
  const decimalWholeNumber = parseInt(priceToDisplay.split(".")[1]);
  const ownedItemBalance = parseInt(state.quantityOwned?.toString() ?? "0");
  //   const totalWholeNumber = parseInt(totalPriceToDisplay.split(".")[0]);
  //   const totalDecimalWholeNumber = parseInt(totalPriceToDisplay.split(".")[1]);
  return (
    <div className="flex flex-col justify-between">
      <div className={`flex flex-row w-full`} style={{ justifyItems: "end" }}>
        <div className="flex flex-row w-full" style={{ gridColumn: "span 4" }}>
          {/* {item.image ? (
            <img
              className="bg-[#381B0C]"
              style={{
                maxHeight: "40px",
                height: "40px",
                width: "40px",
              }}
              src={item.image.replace(
                "https://sandbox-garnet-ipfs-gateway.nursery.reitnorf.com/ipfs/",
                "https://mainnet-game-ipfs-gateway.nursery.reitnorf.com/ipfs/"
              )}
              alt={name}
            />
          ) : null} */}
          <div
            className="flex flex-col w-full px-2"
            style={{ gridColumn: "span 1" }}
          >
            {/* <div className="flex w-full justify-between font-bold">
              <span>{name ?? `Item Type ${typeId}`}</span>
            </div> */}
            <div className="flex flex-row">
              <span className="text-s">Owned Qty: </span>
              <div className="px-2">
                {ownedItemBalance ? (
                  <AnimatedNumber value={ownedItemBalance} duration={250} />
                ) : (
                  <Loading />
                )}
              </div>
            </div>
            <div className="flex flex-row">
              <span className="text-s">Price: </span>
              {state.marketPrice !== 0n ? (
                <>
                  <div className="px-2">
                    <FloatingAnimatedNumber
                      wholeNumber={wholeNumber}
                      decimalWholeNumber={decimalWholeNumber}
                      duration={250}
                    />
                  </div>
                  <span>{tradeAssetTicker}</span>
                </>
              ) : (
                <div className="px-2">Insufficient data.</div>
              )}
            </div>
          </div>
        </div>
        {/* <div style={{ maxWidth: "80px", gridColumn: "span 1" }}>
          {type !== "none" && (
            <Tooltip
              text={type === "purchase" ? "Buy item(s)" : "Sell item(s)"}
            //   disabled={showTradeWindow}
            >
              <EveButton
                typeClass="tertiary"
                className="font-normal"
                onClick={() => setShowTradeWindow(!showTradeWindow)}
              >
                {showTradeWindow
                  ? "Close"
                  : type === "purchase"
                    ? "buy"
                    : "sell"}
              </EveButton>
            </Tooltip>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default DexStorageItem;
