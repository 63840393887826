import { SmartAssemblyType } from "@eveworld/types/types";
import EveScroll from "@eveworld/ui-components/components/EveScroll";
import { useConnection, useSmartObject, useWorld } from "@eveworld/contexts";
import { findOwnerByAddress, isOwner } from "@eveworld/utils/utils";
import { WalletClient } from "viem";

import StorageItem from "./StorageItem";
import CapacityView from "./Capacity";
import FloatingAnimatedNumber from "../FloatingAnimatedNumber";
import { converBigIntToDisplayNumber } from "../../utils/displayFormat";
import { useEffectOnce } from "@/utils/hooks/useEffectOnce";
import { useMUD } from "@/MUDContext";

const UserStorage = ({
  characterId,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
  showTradeAssetBalance = false,
  itemId = null,
}: {
  characterId: string;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
  showTradeAssetBalance?: boolean;
  itemId?: string | null;
}): JSX.Element => {
  const inventoryName = "Your Inventory";
  const {
    systemCalls: { getItemBalance },
  } = useMUD();
  const { smartAssembly } = useSmartObject() as {
    smartAssembly: SmartAssemblyType<"SmartStorageUnit">;
  };
  if (!smartAssembly) return <></>;
  const { publicClient, defaultNetwork, walletClient } = useConnection();
  const isEntityOwner: boolean = isOwner(
    smartAssembly,
    walletClient?.account?.address
  );
  if (!walletClient) return <></>;
  const ephemeralInventoryList =
    smartAssembly.inventory?.ephemeralInventoryList || [];

  const playerInventory = ephemeralInventoryList.find((x) =>
    findOwnerByAddress(x.ownerId, walletClient?.account?.address)
  );

  // If owner, return persistent storage items
  // If player, return own ephemeral storage items
  const inventoryItems = playerInventory?.ephemeralInventoryItems?.filter(
    (item) => {
      return item && item.name;
    }
  );
  const ssuItems = smartAssembly.inventory?.storageItems?.filter((item) => {
    return item && item.name;
  });

  const storageCap = playerInventory?.storageCapacity || null;
  const usedCap = playerInventory?.usedCapacity || null;
  const disabledMsg = smartAssembly.isOnline
    ? null
    : "SSU is offline. Must be online to trade.";
  const number = converBigIntToDisplayNumber(
    tradeAssetBalance,
    tradeAssetDecimals
  );
  const itemsToDisplay = isEntityOwner ? ssuItems : inventoryItems;
  return (
    <div className="flex flex-col w-full">
      <div className="Quantum-Container Title">{`${inventoryName}`}</div>
      <div className="flex flex-row w-full">
        <CapacityView
          inventoryName={inventoryName}
          usedCap={usedCap}
          ofCap={storageCap}
        />
        {showTradeAssetBalance && (
          <div className="flex flex-col">
            <div className="Quantum-Container Title">
              {tradeAssetTicker} Balance:{" "}
            </div>
            <div
              className="Quantum-Container bg-brightquantum text-crude text-s font-bold"
              id="smartassembly-invcapacity"
            >
              <div className="flex flex-row" style={{ alignSelf: "end" }}>
                <FloatingAnimatedNumber
                  wholeNumber={parseInt(number.split(".")[0])}
                  decimalWholeNumber={parseInt(number.split(".")[1])}
                  duration={1000}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <EveScroll maxHeight="500px" id="smartassembly-inventory">
        <div className="Quantum-Container text-xs flex flex-col !py-4 gap-2 min-h-full">
          {!itemsToDisplay || itemsToDisplay.length === 0 ? (
            <div>
              Empty. Add items to the SSU to see them appear here. You may need
              to refresh this app to see them after depositing.
            </div>
          ) : (
            itemsToDisplay?.map((item, index) => (
              <div
                key={index}
                className="border border-brightquantum gap-1 p-1"
              >
                <StorageItem
                  item={item}
                  walletClient={walletClient}
                  publicClient={publicClient}
                  defaultNetwork={defaultNetwork}
                  assemblyId={BigInt(smartAssembly.id)}
                  type={"sell"}
                  tradeAssetBalance={tradeAssetBalance}
                  tradeAssetTicker={tradeAssetTicker}
                  tradeAssetDecimals={tradeAssetDecimals}
                  disabledMsg={disabledMsg}
                />
              </div>
            ))
          )}
        </div>
      </EveScroll>
    </div>
  );
};

export default UserStorage;
