import { SavedOrder } from "@/types/orders";
import { FullItemMetadata } from "./types";

export interface IItemMarketState {
  isLoading: boolean;
  itemOrders: SavedOrder[];
  isLoadingItemMeta: boolean;
  itemMeta: FullItemMetadata | undefined;
  marketPrice: bigint | null;
  quantityOwned: bigint | null;
  isFetchingPrice: boolean;
  isFetchingQuantity: boolean;
  errorFetchingMarketPrice?: string | null;
  errorFetchingQuantity?: string | null;
  isSubmittingOrder?: boolean;
}

// Used to represent stacked orders by locale and price based on original SavedOrder
export const baseState: IItemMarketState = {
  isLoading: false,
  itemOrders: [],
  isLoadingItemMeta: false,
  itemMeta: undefined,
  marketPrice: null,
  quantityOwned: null,
  isFetchingPrice: true,
  isFetchingQuantity: true,
  errorFetchingMarketPrice: null,
  errorFetchingQuantity: null,
};

export type IActions =
  | { type: "SET_ITEM_ORDERS"; payload: SavedOrder[] }
  | { type: "SET_ITEM_META"; payload: FullItemMetadata }
  | { type: "SET_LOADING"; payload: boolean }
  | {
      type: "FETCHED_PRICE_SUCCESS";
      payload: { value: bigint; error: string | null };
    }
  | {
      type: "FETCHED_PRICE_ERROR";
      payload: { value: null; error: string | null };
    }
  | { type: "START_FETCHING_PRICE" }
  | {
      type: "FETCHED_QUANTITY_SUCCESS";
      payload: bigint;
    }
  | {
      type: "FETCHED_QUANTITY_ERROR";
      payload: string;
    }
  | {
      type: "START_FETCHING_QUANTITY";
    }
  | { type: "SET_USER_ITEM_BALANCE"; payload: bigint };

export const singleItemMarketReducer = (
  state: IItemMarketState,
  action: IActions
): IItemMarketState => {
  switch (action.type) {
    case "SET_ITEM_ORDERS":
      return {
        ...state,
        isLoading: false,
        itemOrders: action.payload,
      };
    case "SET_ITEM_META":
      return {
        ...state,
        isLoadingItemMeta: false,
        itemMeta: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "START_FETCHING_PRICE":
      return {
        ...state,
        isFetchingPrice: true,
      };
    case "FETCHED_PRICE_SUCCESS":
      return {
        ...state,
        marketPrice: action.payload.value,
        isFetchingPrice: false,
      };
    case "FETCHED_PRICE_ERROR":
      return {
        ...state,
        errorFetchingMarketPrice: action.payload.error,
      };
    case "START_FETCHING_QUANTITY":
      return {
        ...state,
        isFetchingQuantity: true,
      };
    case "FETCHED_QUANTITY_SUCCESS":
      return {
        ...state,
        isFetchingQuantity: false,
        quantityOwned: action.payload,
      };
    case "FETCHED_QUANTITY_ERROR":
      return {
        ...state,
        errorFetchingQuantity: action.payload,
      };
    case "SET_USER_ITEM_BALANCE":
      return {
        ...state,
        quantityOwned: action.payload,
      };
    default:
      return state;
  }
};
