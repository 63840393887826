import { OrderType } from "../types/orders";
import { DEFAULT_DECIMAL_PLACES_TO_DISPLAY } from "../constants";

export const formatDisplayPrice = (
  price: bigint,
  decimals: number = 18,
  maxDecimals: number = DEFAULT_DECIMAL_PLACES_TO_DISPLAY
): string => {
  // Calculate the integer part
  const integerPart = price / BigInt(10 ** decimals);
  const fractionalPart = price % BigInt(10 ** decimals); // get remainder

  const fractionalString = fractionalPart
    .toString()
    .padStart(decimals, "0")
    .slice(0, maxDecimals); // format to 18 decimals
  return `${integerPart}.${fractionalString}`;
};

export const converBigIntToDisplayNumber = (
  quantity: bigint,
  decimals: number,
  maxDecimals: number = DEFAULT_DECIMAL_PLACES_TO_DISPLAY
) => {
  // Calculate the integer part
  const integerPart = quantity / BigInt(10 ** decimals);
  const fractionalPart = quantity % BigInt(10 ** decimals); // get remainder
  const fractionalString = fractionalPart.toString().padStart(decimals, "0"); // format to 6 decimal places
  // strip trailing zeros
  // const fractionalStringNoTrailingZeros = fractionalString.replace(/0+$/, "");
  return `${integerPart}.${fractionalString}`;
};

export const deriveNumberParts = (
  quantity: bigint,
  decimals: number,
  maxDecimals: number = DEFAULT_DECIMAL_PLACES_TO_DISPLAY
): { wholeNumber: string; decimal: string } => {
  // Calculate the integer part
  const integerPart = quantity / BigInt(10 ** decimals);
  const fractionalPart = quantity % BigInt(10 ** decimals); // get remainder
  const fractionalString = fractionalPart
    .toString()
    .padStart(decimals, "0")
    .slice(0, maxDecimals); // format to 6 decimal places
  return { wholeNumber: integerPart.toString(), decimal: fractionalString };
};

/**
 * Derive the order type from the number representation of the OrderType enum
 * @param orderType - should be a number representation of the OrderType enum
 */
export const deriveOrderType = (orderType: number): OrderType => {
  switch (orderType) {
    case 0:
      return OrderType.LIMIT_BUY;
    case 1:
      return OrderType.LIMIT_SELL;
    case 2:
      return OrderType.MARKET_BUY;
    default:
    case 3:
      return OrderType.MARKET_SELL;
  }
};

export const deriveOrderTypeDisplay = (orderType: number): string => {
  switch (orderType) {
    case 0:
      return "Limit Buy";
    case 1:
      return "Limit Sell";
    case 2:
      return "Market Buy";
    default:
    case 3:
      return "Market Sell";
  }
};
