export const createNavLocation = (
  searchParams: URLSearchParams,
  path: string = "/"
) =>
  `${path}${Array.from(searchParams.entries()).reduce(
    (acc, [key, value], idx) => {
      acc += idx === 0 ? `?${key}=${value}` : `&${key}=${value}`;
      return acc;
    },
    ""
  )}`;
