import { PublicClient } from "viem";
import { default as ERC20Manager } from "./ERC20Manager";

const TradingAdminPanel = ({
  smartAssemblyId,
  publicClient,
  tradeAssetDecimals,
}: {
  smartAssemblyId: bigint;
  publicClient: PublicClient;
  tradeAssetDecimals: number;
}) => {
  return (
    <ERC20Manager
      publicClient={publicClient}
      smartAssemblyId={smartAssemblyId}
      tradeAssetDecimals={tradeAssetDecimals}
    />
  );
};
export default TradingAdminPanel;
