import { DexView } from "./types";
import { PlayerInventory } from "./views/PlayerInventory";
import { PlayerOrders } from "./views/PlayerOrders";
import { Market } from "./views/Market";

export const deriveViewFromSelectedTab = (view: DexView) => {
  switch (view) {
    case DexView.EPHEMERAL_INVENTORY:
      return PlayerInventory;
    case DexView.YOUR_ORDERS:
      return PlayerOrders;
    // case DexView.TRADE:
    //   return HookedTradeWindow;
    case DexView.MARKETPLACE:
    default:
      return Market;
  }
};
export const deriveViewFromPath = (path: string) => {
  switch (path) {
    case "/dex/orders":
      return DexView.YOUR_ORDERS;
    case "/dex/market":
    default:
      return DexView.MARKETPLACE;
    case "/dex/inventory":
      return DexView.EPHEMERAL_INVENTORY;
  }
};

export const minBigInt = (...values: bigint[]) => {
  return values.reduce((min, current) => (current < min ? current : min));
};

export const sortBigIntAscending = (a: bigint, b: bigint) =>
  a < b ? -1 : a > b ? 1 : 0;

export const sortBigIntDescending = (a: bigint, b: bigint) =>
  a < b ? 1 : a > b ? -1 : 0;
