import { useConnection, useNotification } from "@eveworld/contexts";
import { Severity } from "@eveworld/types";
import React, { createContext, useContext, useState, ReactNode } from "react";
// import { useContractTransactionReceipt } from 'viem';

interface TransactionContextType {
  addTransaction: (txHash: string) => void;
}

const TransactionContext = createContext<TransactionContextType | undefined>(
  undefined
);

export const TransactionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { publicClient } = useConnection(); // Viem provider
  const { notify } = useNotification();
  const [txHashes, setTxHashes] = useState<string[]>([]);

  const addTransaction = (txHash: string) => {
    setTxHashes((prev) => [...prev, txHash]);
    monitorTransaction(txHash);
  };
  if (publicClient === null) {
    throw new Error("Public client is not initialized");
  }

  const monitorTransaction = async (txHash: string) => {
    try {
      const receipt = await publicClient.waitForTransactionReceipt({
        hash: txHash as `0x${string}`,
      }); // Wait for transaction confirmation
      if (receipt.status === "success") {
        console.log(`Transaction succeeded`);
        notify({
          txHash: txHash,
          type: Severity.Success,
        });
        // Perform action on success
      } else {
        console.error(`Transaction failed`);
        notify({
          txHash: txHash,
          type: Severity.Error,
        });

        // Perform action on failure
      }
    } catch (error) {
      console.error(`Error monitoring transaction ${txHash}:`, error);
      // Handle error
    }
  };

  return (
    <TransactionContext.Provider value={{ addTransaction }}>
      {children}
    </TransactionContext.Provider>
  );
};

// Custom hook to access transaction context
export const usePendingTxManager = () => {
  const context = useContext(TransactionContext);
  if (!context) {
    throw new Error(
      "usePendingTxManager must be used within a TransactionProvider"
    );
  }
  return context;
};
