import { useEffect, useState } from "react";
import { SmartAssemblyType } from "@eveworld/types/types";
import { WalletClient } from "viem";
import { deriveOrderType } from "@/utils/displayFormat";
import { OrderType, SavedOrder } from "@/types/orders";
import BaseOrder from "../../Order";
import UserStorage from "../../../EntityComponents/UserStorage";

interface IState {}

const PlayerInventory = ({
  characterId,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
  showTradeAssetBalance = false,
}: {
  characterId: string;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
  showTradeAssetBalance?: boolean;
}) => {
  const [state, setState] = useState<IState>({});
  return (
    <div className="flex">
      <UserStorage
        characterId={characterId}
        tradeAssetBalance={tradeAssetBalance}
        tradeAssetDecimals={tradeAssetDecimals}
        tradeAssetTicker={tradeAssetTicker}
        showTradeAssetBalance={true}
      />
    </div>
  );
};
export default PlayerInventory;
