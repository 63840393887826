export const SETTINGS = {
  labelPosition: [0.1, 0.1, 0.025] as [number, number, number],
};
export const COLOR_THEME = {
  baseSystemColor: 0xadd8e6,
  baseSystemOpacity: 0.7,

  selectedSystemColor: 0x94e5ff,
  selectedSystemOpacity: 1,

  selectedSystemHaloColor: 0xadd8e6,
  selectedSystemHaloOpacity: 0.25,

  selectedDestinationSystemColor: 0xe7071b,
  selectedDestinationSystemOpacity: 1,

  selectedDestinationSystemHaloColor: 0xee515f,
  selectedDestinationSystemHaloOpacity: 0.2,

  selectedConstellationColor: 0xd9a759,
  selectedConstellationOpacity: 1,

  linkColor: 0x808080,
  linkOpacity: 0.3,
};
