import { useEffect, useState } from "react";

import { FullItemMetadata, IFullMarketState, IMarketState } from "./types";
import { useMUD } from "@/MUDContext";
import Loading from "./Loading";
import { GatewayHTTPService } from "@/services";
import { filterItemsToGroups } from "./utils";
import CategoryDisplay from "./Categories";

const baseState: IFullMarketState = {
  itemMeta: new Map(),
  isLoading: true,
};
const FullMarket = ({ state }: { state: IMarketState }) => {
  const [fullMarketState, setFullMarketState] =
    useState<IFullMarketState>(baseState);
  const {
    systemCalls: { getItemsTypeIds },
  } = useMUD();

  const fetchItemTypesFromGateway = async (
    items: { itemId: bigint; typeId: bigint }[]
  ): Promise<FullItemMetadata[]> => {
    // get types from gateway
    const out = await Promise.all(
      items.map(async (item) =>
        GatewayHTTPService.getInstance()
          .getTypeMetadata(item.typeId)
          .then((res: any) => {
            const data = res.data;
            if (!data || !data.cid) {
              console.error("No data found", item.typeId);
              return undefined;
            }
            return { ...item, metadata: res.data };
          })
          .catch((err: any) => {
            console.error(err);
            return undefined;
          })
      )
    )
      .then((d) => {
        return d.filter(Boolean);
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
    const filtered = out.filter(Boolean);
    return filtered as FullItemMetadata[];
  };

  useEffect(() => {
    if (state.itemsAvailable && state.itemsAvailable.length > 0) {
      getItemsTypeIds(state.itemsAvailable)
        .then((d) => {
          const metadatas = d.map((typeId, idx) => {
            return { typeId, itemId: state.itemsAvailable[idx] };
          });
          fetchItemTypesFromGateway(metadatas).then((data) => {
            const newItemsMetadata = new Map();
            data.forEach((item: FullItemMetadata) => {
              newItemsMetadata.set(item.itemId, {
                metadata: item.metadata,
                typeId: item.typeId,
                itemId: item.itemId,
              });
            });
            console.log("New items metadata: ", newItemsMetadata);
            setFullMarketState({
              ...fullMarketState,
              isLoading: false,
              itemMeta: newItemsMetadata,
            });
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log("No items available: ", state.itemsAvailable);
    }
  }, [state.itemsAvailable]);

  if (state.isLoading) {
    return <Loading />;
  }
  if (!state.itemsAvailable || state.itemsAvailable.length == 0) {
    return <div>No items available</div>;
  }
  const itemGroupingToDisplay = filterItemsToGroups(fullMarketState.itemMeta);
  return (
    <div className="flex flex-col w-full align-center">
      <div className="Quantum-Container Title">Local Market</div>
      <div className="Quantum-Container flex flex-col">
        {!fullMarketState.isLoading ? (
          <CategoryDisplay
            itemGroupingToDisplay={itemGroupingToDisplay}
            fullMarketState={fullMarketState}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default FullMarket;
