/*
 * Create the system calls that the client can use to ask
 * for changes in the World state (using the System contracts).
 */

import { OrderType, SavedOrder } from "../types/orders";
import { Location } from "../types/locations";
import { SetupNetworkResult } from "./setupNetwork";

export type SystemCalls = ReturnType<typeof createSystemCalls>;

export const createSystemCalls = (
  /*
   * The parameter list informs TypeScript that:
   *
   * - The first parameter is expected to be a
   *   SetupNetworkResult, as defined in setupNetwork.ts
   *
   *   Out of this parameter, we care about the following fields:
   *   - worldContract (which comes from getContract, see
   *     https://github.com/latticexyz/mud/blob/main/templates/react/packages/client/src/mud/setupNetwork.ts#L63-L69).
   *
   *   - erc20Contract
   *   - useStore
   *   - tables
   */
  {
    worldContract,
    erc20Contract,
    walletClient,
    publicClient,
  }: SetupNetworkResult
) => {
  /*
   * This function is retrieved from the codegen function in contracts/src/codegen/world/IItemTradeSystem.sol
   * And must be used with the v1_orderbook__ prefix due to namespacing
   */
  const getERC20Data = async (
    smartObjectId: bigint
  ): Promise<{
    tokenAddress: string;
    receiver: string;
    decimals: number;
  }> => {
    const result: Promise<{
      tokenAddress: string;
      receiver: string;
      decimals: number;
    }> = (await worldContract.read.v1_orderbook__getERC20Data([
      smartObjectId,
    ])) as unknown as Promise<{
      tokenAddress: string;
      receiver: string;
      decimals: number;
    }>;
    return result;
  };

  const registerERC20Token = async (
    smartObjectId: bigint,
    tokenAddress: `0x${string}`,
    receiver: `0x${string}`
  ) => {
    await worldContract.write.v1_orderbook__registerERC20Token([
      smartObjectId,
      tokenAddress,
      receiver,
    ]);
    return await worldContract.read.v1_orderbook__getERC20Data([smartObjectId]);
  };

  const updateERC20Receiver = async (
    smartObjectId: bigint,
    receiver: `0x${string}`
  ) => {
    await worldContract.write.v1_orderbook__updateERC20Receiver([
      smartObjectId,
      receiver,
    ]);
    return await worldContract.read.v1_orderbook__getERC20Data([smartObjectId]);
  };

  const checkIfAdmin = async (
    smartObjectId: bigint,
    address: `0x${string}`
  ) => {
    return await worldContract.read.v1_orderbook__isOwner([
      smartObjectId,
      address,
    ]);
  };

  /** ITEM PRICE FUNCTIONS */

  const getItemPrice = async (
    itemId: bigint,
    smartObjectId: bigint,
    type: "purchase" | "sell" | "none"
  ): Promise<bigint | undefined> => {
    if (type === "none") return;
    const fnToCall =
      type === "purchase"
        ? worldContract.read.v1_orderbook__getPurchaserPrice
        : worldContract.read.v1_orderbook__getSellerPrice;
    const itemPrice = (await fnToCall([
      itemId,
      smartObjectId,
    ])) as unknown as bigint;
    return itemPrice;
  };

  /** PURCHASE ITEM FUNCTIONS */

  const getErc20Balance = async (address: `0x${string}`): Promise<bigint> => {
    const balance = await erc20Contract.read.balanceOf([address]);
    console.debug("Balance", balance, " for address ", address);
    return balance;
  };

  const getOrderbookDexContractAddress = async (): Promise<string> => {
    const orderbookDexContractAddress =
      (await worldContract.read.v1_orderbook__getSystemAddress()) as unknown as Promise<string>;
    return orderbookDexContractAddress;
  };

  const getOrdersSystemContractAddress = async (): Promise<string> => {
    const ordersSystemContractAddress =
      (await worldContract.read.v1_orderbook__getOrdersSystemAddress()) as unknown as Promise<string>;
    return ordersSystemContractAddress;
  };

  const getOpenPlayerOrders = async (
    characterId: bigint
  ): Promise<SavedOrder[]> => {
    const openPlayerOrders =
      (await worldContract.read.v1_orderbook__getPlayerOpenOrders([
        characterId,
      ])) as unknown as any;
    return openPlayerOrders;
  };

  const getClosedPlayerOrders = async (
    characterId: bigint
  ): Promise<SavedOrder[]> => {
    const closedPlayerOrders =
      (await worldContract.read.v1_orderbook__getClosedPlayerOrders([
        characterId,
      ])) as unknown as any;
    return closedPlayerOrders;
  };

  /** SELL ITEM FUNCTIONS */

  /**
   *
   * @param smartObjectId
   * @param address
   * @returns
   */
  const collectTokens = async (
    smartObjectId: bigint,
    address: `0x${string}`
  ) => {
    await worldContract.write.v1_orderbook__collectTokens([smartObjectId]);
    return await getErc20Balance(address);
  };

  const getStructuresLocations = async (
    smartObjectIds: bigint[]
  ): Promise<Location[]> => {
    const structuresLocations =
      (await worldContract.read.v1_orderbook__getDeployableLocationBySmartObjectIds(
        [smartObjectIds]
      )) as unknown as Location[];
    return structuresLocations;
  };

  /**
   * Get the character id by address from the World contract
   * @param address - The address to check
   */
  const getCharacterIdByAddress = async (address: `0x${string}`) => {
    const characterId = (await worldContract.read.getCharacterIdByAddress([
      address,
    ])) as unknown as bigint;
    return characterId;
  };

  const getMarketPrice = async (
    smartObjectId: bigint,
    itemId: bigint,
    orderType: OrderType
  ): Promise<bigint> => {
    const marketPrice = (await worldContract.read.v1_orderbook__getMarketPrice([
      smartObjectId,
      itemId,
      BigInt(orderType.toString()),
    ])) as unknown as bigint;
    return marketPrice;
  };

  const getItemsOnOrderAtSSU = async (
    smartObjectId: bigint
  ): Promise<bigint[]> => {
    const itemsOnOrder =
      (await worldContract.read.v1_orderbook__getItemsOnOrderAtSSU([
        smartObjectId,
      ])) as unknown as bigint[];
    return itemsOnOrder;
  };

  const getAllItemOrders = async (
    smartObjectId: bigint,
    itemId: bigint
  ): Promise<SavedOrder[]> => {
    const getAllItemOrders =
      (await worldContract.read.v1_orderbook__getAllOrdersForItem([
        smartObjectId,
        itemId,
      ])) as unknown as SavedOrder[];
    return getAllItemOrders;
  };

  /**
   * Get the item balance for a user
   * @param userAddress - The user address
   * @param smartObjectId - The smart object id
   * @param itemId - The item id
   */
  const getItemBalance = async (
    userAddress: string,
    smartObjectId: bigint,
    itemId: bigint
  ) => {
    // console.log(`getItemBalance: ${userAddress}, ${smartObjectId}, ${itemId}`);
    const itemBalance = (await worldContract.read.v1_orderbook__getItemBalance([
      userAddress,
      smartObjectId,
      itemId,
    ])) as unknown as bigint;
    return itemBalance;
  };

  const getItemIsOnOrderAtSSUs = async (
    smartObjectIds: bigint[],
    itemId: bigint
  ) => {
    const itemIsOnOrderAtSSUs =
      (await worldContract.read.v1_orderbook__getItemIsOnOrderAtSSUs([
        smartObjectIds,
        itemId,
      ])) as unknown as boolean[];
    return itemIsOnOrderAtSSUs;
  };

  const getIsActiveSSUs = async (smartObjectIds: bigint[]) => {
    const isActiveSSUs = (await worldContract.read.v1_orderbook__getIsActiveSSU(
      [smartObjectIds]
    )) as unknown as boolean[];
    // console.log("isActiveSSUs", isActiveSSUs, smartObjectIds);
    return isActiveSSUs;
  };

  const getItemOrdersFromSSUs = async (
    smartObjectIds: bigint[],
    itemId: bigint
  ) => {
    const itemOrdersFromSSUs =
      (await worldContract.read.v1_orderbook__getItemOrdersFromSSUs([
        smartObjectIds,
        itemId,
      ])) as unknown as SavedOrder[][];
    // console.log("itemOrdersFromSSUs", itemOrdersFromSSUs, smartObjectIds);
    return itemOrdersFromSSUs;
  };

  const getItemsTypeIds = async (itemIds: bigint[]): Promise<bigint[]> => {
    const itemsTypeIds = (await worldContract.read.v1_orderbook__getItemTypeIds(
      [itemIds]
    )) as unknown as bigint[];
    return itemsTypeIds;
  };
  // const checkIfSSUIsDex = async (smartObjectId: bigint) => {
  //   const dexAddress = await getOrderbookDexContractAddress();

  //   // check if method exists on the contract
  // }

  const registerBuyOrder = async (
    smartObjectId: bigint,
    amount: bigint,
    price: bigint,
    priceWithFee: bigint,
    itemId: bigint,
    characterId: bigint
  ): Promise<bigint> => {
    const orderbookDexAddress =
      (await worldContract.read.v1_orderbook__getSystemAddress()) as unknown as `0x${string}`;
    console.log(
      "approval amount",
      amount,
      priceWithFee,
      amount * BigInt(priceWithFee)
    );
    const approvalAmount = amount * BigInt(priceWithFee);
    // First, approve spend by the contract address
    const approvalProps = [orderbookDexAddress, BigInt(approvalAmount)];
    await erc20Contract.write.approve(approvalProps).catch((err: any) => {
      console.error("Error approving spend", err);
    });
    const orderId = (await worldContract.write.v1_orderbook__registerBuyOrder([
      smartObjectId,
      amount,
      price,
      itemId,
      characterId,
    ])) as unknown as bigint;
    return orderId;
  };

  const registerSellOrder = async (
    smartObjectId: bigint,
    amount: bigint,
    price: bigint,
    itemId: bigint,
    characterId: bigint
  ): Promise<bigint> => {
    const orderId = (await worldContract.write.v1_orderbook__registerSellOrder([
      smartObjectId,
      amount,
      price,
      itemId,
      characterId,
    ])) as unknown as bigint;
    return orderId;
  };

  const getFeeAdjustedPrice = async (
    smartObjectId: bigint,
    itemId: bigint,
    price: bigint,
    orderType: OrderType
  ): Promise<bigint> => {
    const feeAdjustedPrice =
      (await worldContract.read.v1_orderbook__getFeeAdjustedPrice([
        smartObjectId,
        itemId,
        price,
        orderType,
      ])) as unknown as bigint;
    return feeAdjustedPrice;
  };

  const getFeeBasisPoints = async (
    smartObjectId: bigint,
    itemId: bigint
  ): Promise<bigint> => {
    console.log("getFeeBasisPoints", smartObjectId, itemId);
    const feeBasisPoints =
      (await worldContract.read.v1_orderbook__getFeeBasisPoints([
        smartObjectId,
        itemId,
      ])) as unknown as bigint;
    return feeBasisPoints;
  };

  const getCheckIsItemInSSU = async (
    smartObjectId: bigint,
    itemId: bigint
  ): Promise<boolean> => {
    try {
      const isItemInSSU =
        await worldContract.read.v1_orderbook__checkIsItemInSystem([
          smartObjectId,
          itemId,
        ]);
      return isItemInSSU;
    } catch (e) {
      return false;
    }
  };

  /**
   *
   * @param smartObjectId - The smart object id
   * @param itemId - The item id
   * @param thresholdPrice - the maximum price for a buy order, or the minimum price for a sell order. else - reverts
   * @param amount - the amount of the order
   * @param orderType - 2 for market buy order, 3 for market sell order
   */
  const executeMarketOrder = async (
    smartObjectId: bigint,
    itemId: bigint,
    thresholdPrice: bigint,
    amount: bigint,
    orderType: OrderType
  ) => {
    if (orderType === OrderType.MARKET_BUY) {
      const orderbookDexAddress =
        (await worldContract.read.v1_orderbook__getSystemAddress()) as unknown as `0x${string}`;
      if (orderbookDexAddress === "0x") {
        throw new Error("OrderbookDex address not set");
      }
      // approve up to the threshold price.
      const approvalAmount = amount * BigInt(thresholdPrice) + 1n;
      // First, approve spend by the contract address
      const approvalProps = [orderbookDexAddress, BigInt(approvalAmount)];
      await erc20Contract.write.approve(approvalProps).catch((err: any) => {
        console.error("Error approving spend", err);
      });
    }
    const txHash = await worldContract.write.v1_orderbook__executeMarketOrder([
      smartObjectId,
      itemId,
      thresholdPrice,
      amount,
      BigInt(orderType),
    ]);
    return txHash;
  };

  return {
    checkIfAdmin,
    registerERC20Token,
    updateERC20Receiver,
    collectTokens,
    getStructuresLocations,
    getIsActiveSSUs,
    getItemIsOnOrderAtSSUs,
    getItemOrdersFromSSUs,
    getItemPrice,
    getERC20Data,
    getErc20Balance,
    getOrdersSystemContractAddress,
    getOrderbookDexContractAddress,
    getOpenPlayerOrders,
    getClosedPlayerOrders,
    getCharacterIdByAddress,
    getMarketPrice,
    getCheckIsItemInSSU,
    getItemsOnOrderAtSSU,
    getItemsTypeIds,
    getAllItemOrders,
    getItemBalance,
    registerBuyOrder,
    registerSellOrder,
    getFeeAdjustedPrice,
    getFeeBasisPoints,
    executeMarketOrder,
  };
};
