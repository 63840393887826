import { EveButton } from "@eveworld/ui-components";
import { FullItemMetadata } from "./types";
import LineButton from "../../../LineButton";

const MarketItem = ({
  item,
  selectItem,
}: {
  item: FullItemMetadata;
  selectItem: (itemId: bigint) => void;
}) => {
  return (
    <LineButton
      className="Title bg-quantum flex flex-row justify-between w-full"
      onClick={() => {
        return selectItem(item.itemId);
      }}
      key={`select-item-btn-${item.itemId}`}
    >
      <div className="flex flex-row ">
        <div className="flex flex-row w-full" style={{ gridColumn: "span 4" }}>
          {item.metadata?.metadata?.image ? (
            <img
              className="bg-[#381B0C]"
              style={{
                maxHeight: "20px",
                height: "20px",
                width: "20px",
              }}
              src={item.metadata?.metadata?.image.replace(
                "https://sandbox-garnet-ipfs-gateway.nursery.reitnorf.com/ipfs/",
                "https://mainnet-game-ipfs-gateway.nursery.reitnorf.com/ipfs/"
              )}
              alt={item.metadata.name}
            />
          ) : null}
          <div style={{ alignSelf: "center" }}>
            {item.metadata?.metadata?.name || "Unknown"}
          </div>
        </div>
      </div>
    </LineButton>
  );
};

export default MarketItem;
