import { BalanceModule } from "@/components/EntityComponents";
import DexStorageItem from "./DexStorageItem";
import { IItemMarketState } from "./singleItemMarket.reducer";
import { FullItemMetadata } from "./types";

const SingleMarkeItemBalances = ({
  state,
  tradeAssetBalance,
  tradeAssetTicker,
  tradeAssetDecimals,
  item,
}: {
  state: IItemMarketState;
  tradeAssetBalance: bigint;
  tradeAssetTicker: string;
  tradeAssetDecimals: number;
  item: FullItemMetadata;
}) => {
  return (
    <div className="flex flex-row text-xs">
      <DexStorageItem
        state={state}
        item={item}
        // type={"sell"}
        tradeAssetBalance={tradeAssetBalance}
        tradeAssetTicker={tradeAssetTicker}
        tradeAssetDecimals={tradeAssetDecimals}
        // disabledMsg={disabledMsg}
      />
    </div>
  );
};
export default SingleMarkeItemBalances;
