export interface ITradingState {
  itemId: number;
  itemName: string;
  quantity: {
    quantityToTrade: number;
    error: string | undefined;
  };
  price: {
    priceToTrade: bigint;
    error: string | undefined;
  };
  baseAssetTicker: string;
  isTrading: boolean;
}
export enum TradingActionType {
  SET_QUANTITY = "SET_QUANTITY",
  SET_IS_TRADING = "IS_TRADING",
  SET_PRICE = "SET_PRICE",
}
type TradingDispatch = {
  type: TradingActionType;
  payload: any;
};

const TradingReducer = (
  state: ITradingState,
  action: TradingDispatch
): ITradingState => {
  switch (action.type) {
    default:
      throw new Error("Unknown action type");
    case TradingActionType.SET_QUANTITY:
      return {
        ...state,
        quantity: {
          error: action.payload.error,
          quantityToTrade: action.payload.quantityToTrade,
        },
      };
    case TradingActionType.SET_PRICE:
      return {
        ...state,
        price: {
          error: action.payload.error,
          priceToTrade: action.payload.priceToTrade,
        },
      };
    case TradingActionType.SET_IS_TRADING:
      return {
        ...state,
        isTrading: action.payload,
      };
  }
};

export default TradingReducer;
